import {
  ReactNode,
  RefObject,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { z } from "zod";
import { CommunicationError } from "../../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../../main-api";
import { useAccountingClientId } from "../../accounting-client-id";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../communication-errors/communication-error-messages";
import { SortColumn, SortingContext } from "../sorting/sorting-context";
import {
  QuickFilters,
  QuickFiltersContext,
} from "../top-section/navbar/quick-filters/quick-filters-context";
import {
  FiltersType,
  FiltersContext,
} from "../top-section/navbar/filters/filters-context";
import { toDateString } from "../../../../../../dates";
import { throwError } from "../../../../../../throw-error";

const DocumentPageSchema = z.object({
  id: z.string(),
});

const DocumentIssueSchema = z.union([
  z.object({
    id: z.string(),
    type: z.literal("review_needed"),
    description: z.string(),
  }),
  z.object({
    id: z.string(),
    type: z.literal("invalid_ibans"),
  }),
]);

const ReviewFlagSchema = z.object({
  review_flag: z.boolean().nullable(),
  review_flag_description: z.string().nullable(),
});

export const VatAmountSchema = z.object({
  vat_rate: z.number(),
  vat_amount: z.number(),
});

const IbanSchema = z.object({
  iban: z.string(),
});

const GeneralInformationSchema = z.object({
  date: z.coerce.date().nullable(),
  due_date: z.coerce.date().nullable(),
  total_amount: z.coerce.number().nullable(),
  subtotal: z.coerce.number().nullable(),
  currency: z.string().nullable(),
  iban_list: z.array(IbanSchema),
  vat_amounts: z.array(VatAmountSchema),
  invoice_number: z.string().nullable(),
  client_source_name: z.string().nullable(),
  client_source_address: z.string().nullable(),
  client_destination_name: z.string().nullable(),
  client_destination_address: z.string().nullable(),
  client_source_vat_id: z.string().nullable(),
  client_source_tax_number: z.string().nullable(),
  payment_terms: z.string().nullable(),
});

const ItemDetailSchema = z.object({
  item_description: z.string().nullable(),
  item_quantity: z.coerce.number().nullable(),
  unit: z.string().nullable(),
  unit_price: z.coerce.number().nullable(),
  currency: z.string().nullable(),
  product_code: z.string().nullable(),
  item_date: z.coerce.date().nullable(),
  tax: z.coerce.number().nullable(),
  amount: z.coerce.number().nullable(),
});

const DocumentDataSchema = z.object({
  id: z.string(),
  document_type: z.string(),
  review_flag: ReviewFlagSchema.nullable(),
  general_information: GeneralInformationSchema,
  item_details: z.array(ItemDetailSchema),
});

export const DocumentSchema = z.object({
  id: z.string(),
  name: z.string(),
  created_at: z.string(),
  file_upload_blob_path: z.string().nullable(),
  document_data: DocumentDataSchema,
  issues: z.array(DocumentIssueSchema),
  pages: z.array(DocumentPageSchema),
});

export type Document = z.infer<typeof DocumentSchema>;

type RequestedParams = {
  offset: number;
  sortColumn: SortColumn | undefined;
  quickFilters: QuickFilters;
  filters: FiltersType;
  accountingClientId: string;
};

function useProvider({ toastRef }: { toastRef: RefObject<Toast> }) {
  const mainApi = useMainApi();

  const { sortColumn } = useContext(SortingContext) || throwError();

  const { quickFilters } = useContext(QuickFiltersContext) || throwError();

  const { filters } = useContext(FiltersContext) || throwError();

  const accountingClientId = useAccountingClientId();

  const [offset, setOffset] = useState(0);

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        total: number;
        rows: Document[];
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const [lastRequestParams, setLastRequestParams] = useState<
    RequestedParams | undefined
  >();

  const [selectedDocumentId, setSelectedDocumentId] = useState<
    string | undefined
  >();

  useEffect(() => {
    (async () => {
      if (results.loading) {
        return;
      }

      const requestParams: RequestedParams = {
        offset,
        sortColumn,
        quickFilters,
        filters,
        accountingClientId,
      };

      if (JSON.stringify(requestParams) === JSON.stringify(lastRequestParams)) {
        return;
      }

      setLastRequestParams(requestParams);

      const resetResults =
        JSON.stringify({
          accountingClientId: requestParams.accountingClientId,
          sortColumn: requestParams.sortColumn,
          quickFilters: requestParams.quickFilters,
          filters: requestParams.filters,
        }) !==
        JSON.stringify({
          accountingClientId: lastRequestParams?.accountingClientId,
          sortColumn: lastRequestParams?.sortColumn,
          quickFilters: lastRequestParams?.quickFilters,
          filters: lastRequestParams?.filters,
        });

      if (resetResults) {
        setResults(() => {
          return {};
        });
      }

      const _offset = resetResults ? 0 : offset;

      /* --- */

      setResults((results) => {
        return {
          ...results,
          loading: true,
          error: undefined,
        };
      });

      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append("accounting_client_id", accountingClientId);
      urlSearchParams.append("sort_by", sortColumn?.columnKey ?? "created_at");
      urlSearchParams.append("sort_order", sortColumn?.direction ?? "DESC");
      urlSearchParams.append("offset", _offset.toString());

      for (const [key, value] of Object.entries({
        ...filters,
        ...quickFilters,
      })) {
        if (typeof value === "string" && value.trim().length > 0) {
          urlSearchParams.append(key, value);
        } else if (typeof value === "boolean") {
          urlSearchParams.append(key, value ? "true" : "false");
        } else if (value instanceof Date) {
          urlSearchParams.append(key, toDateString(value));
        }
      }

      const response = await mainApi.fetchJSON({
        method: "GET",
        path: `/documents/accounting-dasboard?${urlSearchParams.toString()}`,
        schema: z.object({
          status: z.literal(200),
          body: z.object({
            total: z.number(),
            documents: z.array(DocumentSchema),
          }),
        }),
      });

      if (response.error) {
        setResults((results) => {
          return {
            ...results,
            loading: false,
            error: response.error,
          };
        });

        toastRef.current?.show({
          severity: "error",
          summary: getCommunicationErrorTitle(response.error),
          detail: getCommunicationErrorMessage(response.error),
        });

        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: false,
          data: {
            total: response.response.body.total,
            rows: resetResults
              ? response.response.body.documents
              : [
                  ...(results.data?.rows || []),
                  ...response.response.body.documents,
                ],
          },
        };
      });
    })();
  }, [
    accountingClientId,
    mainApi,
    offset,
    toastRef,
    results,
    lastRequestParams,
    quickFilters,
    filters,
    sortColumn,
  ]);

  const selectedDocument = results.data?.rows.find(
    (document) => document.id === selectedDocumentId
  );

  return useMemo(() => {
    return {
      offset,
      setOffset,
      selectedDocumentId,
      setSelectedDocumentId,
      results,
      setResults,
      selectedDocument,
    };
  }, [offset, selectedDocumentId, results, selectedDocument]);
}

export const ResultsContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function ResultsContextProvider(props: { children: ReactNode }) {
  const toastRef = useRef<Toast>(null);
  const value = useProvider({ toastRef });

  return (
    <ResultsContext.Provider value={value}>
      <Toast ref={toastRef} />
      {props.children}
    </ResultsContext.Provider>
  );
}
