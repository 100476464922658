import { Document } from "../../results/results-context";
import { MAIN_API_URL } from "../../../../../main-api";
import styles from "./document-pages-preview.module.scss";
import { Carousel } from "primereact/carousel";
import { Button } from "primereact/button";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  download: "Herunterladen",
};

const ENGLISH_TRANSLATIONS = {
  download: "Download",
};

function Page(props: { document: Document; page: { id: string } }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.previewContainer}>
      <>
        <img
          src={`${MAIN_API_URL}/documents/accounting-client/${props.document.id}/page/${props.page.id}/file`}
          height="100%"
        />
        <div className={styles.overlay}>
          <Button
            label={translations.download}
            icon="pi pi-download"
            onClick={() => {
              window.open(
                `${MAIN_API_URL}/documents/accounting-client/${props.document.id}/page/${props.page.id}/file`
              );
            }}
          />
        </div>
      </>
    </div>
  );
}

export function DocumentPagesPreview(props: { document: Document }) {
  if (props.document.document_upload.type != "mobile") {
    throw new Error();
  }

  return (
    <div>
      <Carousel
        value={props.document.document_upload.pages}
        numVisible={1}
        numScroll={1}
        itemTemplate={(page: { id: string }) => (
          <Page document={props.document} page={page} />
        )}
        showIndicators={false}
      />
    </div>
  );
}
