import { ReactNode, createContext, useMemo, useState } from "react";
import { z } from "zod";

export const FiltersSchema = z.object({
  search: z.string().nullable(),
  issues: z.boolean().nullable(),
});

export type FiltersType = z.TypeOf<typeof FiltersSchema>;

function useProvider() {
  const [filters, setFilters] = useState<FiltersType>({
    search: null,
    issues: null,
  });

  return useMemo(() => {
    return {
      filters,
      setFilters,
    };
  }, [filters]);
}

export const FiltersContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function FiltersContextProvider(props: { children: ReactNode }) {
  const value = useProvider();

  return (
    <FiltersContext.Provider value={value}>
      {props.children}
    </FiltersContext.Provider>
  );
}
