import { ReactNode } from "react";
import styles from "./page.module.scss"
import { QuickFiltersContextProvider } from "./top-section/navbar/quick-filters/quick-filters-context";
import { FiltersContextProvider } from "./top-section/navbar/filters/filters-context";
import { TopSection } from "./top-section/top-section";
import { Results } from "./results/results";
import { DetailsPanel } from "./details-panel/details-panel";
import { ResultsContextProvider } from "./results/results-context";
import { SortingContextProvider } from "./sorting/sorting-context";

function Page(props: { children: ReactNode }) {
    return <div className={styles.page}>{props.children}</div>;
}

export function DocumentsPage() {
    return (
        <>
            <QuickFiltersContextProvider>
                <FiltersContextProvider>
                    <SortingContextProvider>
                        <ResultsContextProvider>
                            <Page>
                                <TopSection />
                                <Results />
                                <DetailsPanel />
                            </Page>
                        </ResultsContextProvider>
                    </SortingContextProvider>
                </FiltersContextProvider>
            </QuickFiltersContextProvider>
        </>
    );
}
