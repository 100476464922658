import { Filters } from "./filters/filters";
import { QuickFilters } from "./quick-filters/quick-filters";
import styles from "../top-section.module.scss";

export function NavBar() {
  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.navbarItems}>
          <QuickFilters />
        </div>

        <div className={styles.navbarItems}>
          <Filters />
        </div>
      </div>
    </>
  );
}
