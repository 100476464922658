import styles from "./edit-vat-inclusive-overview.module.scss";
import { useId } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { EditDocument } from "../edit-document-data-schemas";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  subtotal: "Zwischensumme",
  vat: "MwSt",
  total: "Gesamt",
};

const ENGLISH_TRANSLATIONS = {
  subtotal: "Subtotal",
  vat: "VAT",
  total: "Total",
};

export function EditVatInclusiveOverview(props: {
  form: UseFormReturn<EditDocument>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const idPrefix = useId();

  const fieldArray = useFieldArray({
    control: props.form.control,
    name: "changed.general_information.vat_amounts",
  });

  return (
    <>
      <div className={styles.formField}>
        <div className={styles.inputGroup}>
          <label htmlFor={`${idPrefix}-subtotal`} className={styles.label}>
            <b>{translations.subtotal}</b>
          </label>
          <Controller
            name="changed.general_information.subtotal"
            control={props.form.control}
            render={({ field, fieldState }) => (
              <InputNumber
                id={`${idPrefix}-subtotal`}
                value={field.value}
                onValueChange={(e) => field.onChange(e.target.value)}
                className={`${styles.input} ${fieldState.error ? "p-invalid" : ""}`}
                placeholder="Subtotal"
                inputClassName={styles.width100}
                mode="decimal"
                minFractionDigits={1}
                maxFractionDigits={3}
                min={0}
              />
            )}
          />
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor={`${idPrefix}-vat`} className={styles.label}>
            <b>{translations.vat}</b>
          </label>

          <div className={styles.flexDirectionColumn}>
            {fieldArray.fields.map((field, index) => (
              <div className={styles.iterableInputGroup} key={field.id}>
                <div>
                  <Controller
                    name={`changed.general_information.vat_amounts.${index}.vat_rate`}
                    control={props.form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputNumber
                          id={`${idPrefix}-vat-rate-${index}`}
                          value={field.value}
                          onValueChange={(e) => field.onChange(e.target.value)}
                          className={`${styles.input} ${fieldState.error ? "p-invalid" : ""}`}
                          placeholder="Vat Percentage"
                          mode="decimal"
                          minFractionDigits={1}
                          maxFractionDigits={3}
                          min={0}
                        />
                        {fieldState.error && (
                          <small className={styles.errorMessage}>
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
                <div>
                  <Controller
                    name={`changed.general_information.vat_amounts.${index}.vat_amount`}
                    control={props.form.control}
                    render={({ field, fieldState }) => (
                      <>
                        <InputNumber
                          id={`${idPrefix}-vat-amount-${index}`}
                          value={field.value}
                          onValueChange={(e) => field.onChange(e.target.value)}
                          className={`${styles.input} ${fieldState.error ? "p-invalid" : ""}`}
                          placeholder="Vat Amount"
                          mode="decimal"
                          minFractionDigits={1}
                          maxFractionDigits={3}
                          min={0}
                        />
                        {fieldState.error && (
                          <small className={styles.errorMessage}>
                            {fieldState.error.message}
                          </small>
                        )}
                      </>
                    )}
                  />
                </div>
                <Button
                  icon="pi pi-trash"
                  text
                  type="button"
                  size="large"
                  className={`${styles.marginSpacer} p-button-danger`}
                  onClick={() => {
                    fieldArray.remove(index);
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        <div className={styles.alignEnd}>
          <Button
            className={styles.width100}
            icon="pi pi-plus"
            type="button"
            severity={"secondary"}
            size="large"
            onClick={() => {
              fieldArray.append({
                vat_rate: 0,
                vat_amount: 0,
              });
            }}
          />
        </div>

        <div className={styles.inputGroup}>
          <label htmlFor={`${idPrefix}-total`} className={styles.label}>
            <b>{translations.total}</b>
          </label>
          <Controller
            name="changed.general_information.total_amount"
            control={props.form.control}
            render={({ field, fieldState }) => (
              <InputNumber
                id={`${idPrefix}-total`}
                value={field.value}
                onValueChange={(e) => field.onChange(e.target.value)}
                placeholder="Total"
                className={`${styles.input} ${fieldState.error ? "p-invalid" : ""}`}
                inputClassName={styles.width100}
                mode="decimal"
                minFractionDigits={1}
                maxFractionDigits={3}
                min={0}
              />
            )}
          />
        </div>
      </div>
    </>
  );
}
