import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { Document } from "../../results/results-context";
import styles from "./issues.module.scss";

const GERMAN_TRANSLATIONS = {
  reviewNeededTitle: "Überprüfung erforderlich",
  reviewNeededDescription:
    "Einige Dokumentfelder mussen geprüft und korrigiert werden.",
  invalidIbansTitle: "Ungültige IBANs",
  invalidIbansDescription:
    "Einige IBANs im Dokument sind ungültig und sollten korrigiert werden.",
};

const ENGLISH_TRANSLATIONS = {
  reviewNeededTitle: "Review needed",
  reviewNeededDescription:
    "Some of the document fields need to be reviewed and corrected.",
  invalidIbansTitle: "Invalid IBANs",
  invalidIbansDescription:
    "Some of the IBANs in the document are invalid and need to be corrected.",
};

export function Issues(props: { selectedDocument: Document }) {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <div>
        <h3 className={styles.marginBottom}>
          {currentLanguage === SupportedLanguage.German ? "Probleme" : "Issues"}
        </h3>
        <div className={styles.issues}>
          {props.selectedDocument.issues?.map((_issue) => {
            const issue: {
              id: string;
              type: string;
              title?: string;
              description?: string;
            } = {
              ..._issue,
            };

            let issueTitle: string;
            let issueDescription: string;

            if (_issue.type === "review_needed") {
              issueTitle = translations.reviewNeededTitle;
              issueDescription = translations.reviewNeededDescription;
            } else if (_issue.type === "invalid_ibans") {
              issueTitle = translations.invalidIbansTitle;
              issueDescription = translations.invalidIbansDescription;
            } else {
              throw new Error();
            }

            return (
              <div key={issue.id}>
                <h4 className={styles.marginBottom}>{issueTitle}</h4>
                <p>{issueDescription}</p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
