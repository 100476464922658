import styles from "./edit-ibans.module.scss";
import { useId } from "react";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { EditDocument } from "../edit-document-data-schemas";

export function EditIbanList(props: { form: UseFormReturn<EditDocument> }) {
  const { fields, append, remove } = useFieldArray({
    control: props.form.control,
    name: "changed.general_information.iban_list",
  });

  const idPrefix = useId();

  return (
    <div className={styles.formField}>
      <div className={`${styles.width100} ${styles.marginBottomSpacer} `}>
        <label htmlFor={`${idPrefix}-ibans`} className={styles.label}>
          <b className={styles.title}>IBANs</b>
        </label>

        {fields.map((field, index) => (
          <div key={field.id} className={styles.listedInputField}>
            <Controller
              name={`changed.general_information.iban_list.${index}.iban`}
              control={props.form.control}
              render={({ field, fieldState }) => (
                <div className={styles.width100}>
                  <InputText
                    id={`${idPrefix}-iban-${index}`}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    className={`${styles.input} ${fieldState.error ? "p-invalid" : ""}`}
                  />
                  {fieldState.error && (
                    <small className={styles.errorMessage}>
                      {fieldState.error.message}
                    </small>
                  )}
                </div>
              )}
            />

            <Button
              icon="pi pi-trash"
              text
              type="button"
              className={`${styles.marginSpacer} p-button-danger`}
              size="large"
              onClick={() => {
                remove(index);
              }}
            />
          </div>
        ))}
      </div>
      <div className={styles.width100}>
        <Button
          className={styles.width100}
          severity="secondary"
          type="button"
          icon="pi pi-plus"
          size="large"
          onClick={() => append({ iban: "" })}
        />
      </div>
    </div>
  );
}
