import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { useContext, useMemo } from "react";
import { AccountingClientContext } from "./accounting-client-context";
import { throwError } from "../../../../../throw-error";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  selectClient: "Mandanten auswählen",
};

const ENGLISH_TRANSLATIONS = {
  selectClient: "Select a client",
};

export function AccountingClientDropdown(props: { className?: string }) {
  const {
    accountingClients,
    setSelectedAccountingClient,
    selectedAccountingClient,
  } = useContext(AccountingClientContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const accountingCientOptions = useMemo(() => {
    return accountingClients.data?.map((client) => ({
      label: `${client.client_number} | ${client.name}`,
      value: `${client.id}`,
    }));
  }, [accountingClients.data]);

  const handleDropdownChange = (e: DropdownChangeEvent) => {
    const selectedId = e.value;

    const selectedClient = accountingClients.data?.find(
      (client) => client.id === selectedId
    );

    setSelectedAccountingClient(selectedClient);
  };

  return (
    <Dropdown
      className={props.className}
      value={selectedAccountingClient ? selectedAccountingClient.id : null}
      options={accountingCientOptions}
      optionLabel="label"
      onChange={handleDropdownChange}
      placeholder={translations.selectClient}
      showClear
    />
  );
}
