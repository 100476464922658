import { Filters } from "./filters/filters"
import styles from "./navbar.module.scss"
import { QuickFilters } from "./quick-filters/quick-filters"
import { DocumentUploadDialog } from "./upload-document/document-upload-dialog"
export function Navbar() {
    return (
        <>
            <div className={styles.navbar}>
                <div className={styles.navbarItems}>
                    <div className={styles.buttonBar}>
                        <QuickFilters />
                    </div>
                </div>

                <div className={styles.navbarItems}>
                    <div className={styles.buttonBar}>
                        <DocumentUploadDialog />
                        <Filters />
                    </div>
                </div>
            </div >
        </>
    )
}