import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import styles from "./policies.module.scss";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  title: "Datenschutzerklärung für das Kontodashboard-System",
  effectiveDate: "Gültig ab: 24.10.2024",
  lastUpdated: "Letzte Aktualisierung: 25.11.2024",
  welcomeMessage:
    "Willkommen bei Kanzlei21. Wir schätzen Ihre Privatsphäre und verpflichten uns, Ihre persönlichen Daten mit größter Sorgfalt zu behandeln und alle geltenden Gesetze und Vorschriften, einschließlich der Datenschutz-Grundverordnung (DSGVO), einzuhalten.",
  sections: [
    {
      title: "1. Informationen, die wir sammeln",
      content: `Wir erfassen verschiedene Arten von persönlichen Informationen, um unsere Dienstleistungen bereitzustellen und zu verbessern. Dazu gehören: Konto-Informationen, Nutzungsdaten, Kommunikationsdaten, Transaktionsdaten, Cookies und Tracking-Technologien sowie Fehlerprotokolle und Sitzungsaufzeichnungen.`,
    },
    {
      title: "2. Verwendung Ihrer Informationen",
      content:
        "Wir verwenden die gesammelten Daten für die Bereitstellung von Diensten, Verbesserung der Benutzererfahrung, Kommunikation und zur Einhaltung gesetzlicher Anforderungen.",
    },
    {
      title: "3. Rechtsgrundlage für die Verarbeitung personenbezogener Daten",
      content:
        "Wir verarbeiten Ihre personenbezogenen Daten auf Grundlage Ihrer Einwilligung, vertraglicher Verpflichtungen, legitimer Interessen und rechtlicher Verpflichtungen.",
    },
    {
      title: "4. Weitergabe Ihrer Informationen",
      content:
        "Wir verkaufen oder teilen Ihre persönlichen Daten nicht für eigene Marketingzwecke an Dritte. Wir arbeiten jedoch mit vertrauenswürdigen Drittanbietern wie Plaid und Sentry zusammen.",
    },
    {
      title: "5. Datenspeicherung",
      content:
        "Wir bewahren Ihre personenbezogenen Daten nur so lange auf, wie es für die beschriebenen Zwecke erforderlich ist oder gesetzlich vorgeschrieben ist.",
    },
    {
      title: "6. Ihre Rechte",
      content:
        "Unter der DSGVO haben Sie das Recht auf Zugriff, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit und Widerspruch.",
    },
    {
      title: "7. Benutzerzustimmung für Finanzdatenverarbeitung",
      content:
        "Durch die Nutzung unserer Dienste erteilen Sie ausdrücklich Ihre Zustimmung, dass Ihr Steuerberater Ihre GDPdU-Daten hochlädt und Ihre Banktransaktionsdaten für Buchhaltungszwecke verarbeitet. Diese Zustimmung ist erforderlich, um die vertraglichen Verpflichtungen der Dienste zu erfüllen.",
    },
    {
      title: "8. Sicherheit",
      content:
        "Wir verwenden geeignete technische und organisatorische Maßnahmen, um Ihre persönlichen Daten vor unbefugtem Zugriff, Missbrauch oder Verlust zu schützen.",
    },
    {
      title: "9. Cookies und Tracking-Technologien",
      content:
        "Wir verwenden Cookies und ähnliche Technologien zur Verbesserung der Benutzererfahrung. Sie können Ihre Cookie-Einstellungen in Ihrem Browser verwalten.",
    },
    {
      title: "10. Automatisierte Entscheidungsfindung und Profiling",
      content:
        "Wir verwenden Ihre personenbezogenen Daten nicht für automatisierte Entscheidungen, die rechtliche oder ähnliche Auswirkungen auf Sie haben.",
    },
    {
      title: "11. Benachrichtigung bei Datenpannen",
      content:
        "Im Falle einer Datenpanne werden wir Sie und die zuständigen Aufsichtsbehörden gemäß den Anforderungen der DSGVO unverzüglich informieren.",
    },
    {
      title: "12. Internationale Datenübertragungen",
      content:
        "Ihre persönlichen Daten werden nicht außerhalb des Europäischen Wirtschaftsraums (EWR) übertragen.",
    },
    {
      title: "13. Änderungen an dieser Richtlinie",
      content:
        "Wir können diese Datenschutzrichtlinie gelegentlich aktualisieren. Alle Änderungen werden auf dieser Seite veröffentlicht.",
    },
    {
      title: "14. Links zu Drittanbietern",
      content:
        "Unser System enthält keine Links zu Drittanbietern. Die einzige Integration erfolgt über Plaid zur sicheren Transaktionsabfrage.",
    },
    {
      title: "15. Kontaktinformationen",
      content:
        "Wenn Sie Fragen zu dieser Datenschutzrichtlinie oder zur Verarbeitung Ihrer persönlichen Daten haben, kontaktieren Sie uns unter support@kanzlei21.com.",
    },
  ],
};

const ENGLISH_TRANSLATIONS = {
  title: "Privacy Policy for Account Dashboard System",
  effectiveDate: "Effective Date: 24.10.2024",
  lastUpdated: "Last Updated: 25.11.2024",
  welcomeMessage:
    "Welcome to Kanzlei21. We value your privacy and are committed to ensuring that your personal data is handled with the utmost care, in full compliance with applicable laws and regulations, including the General Data Protection Regulation (GDPR).",
  sections: [
    {
      title: "1. Information We Collect",
      content: `We collect different types of personal information to provide and improve our services. This includes account information, usage data, communication data, transaction data, cookies and tracking technologies, and error logs and session recording.`,
    },
    {
      title: "2. How We Use Your Information",
      content:
        "We use the collected data for providing services, improving user experience, communication, and legal compliance.",
    },
    {
      title: "3. Legal Basis for Processing Personal Data",
      content:
        "We process your personal data based on your consent, contractual obligations, legitimate interests, and legal obligations.",
    },
    {
      title: "4. Sharing Your Information",
      content:
        "We do not sell or share your personal data for third-party marketing. However, we work with trusted third-party providers like Plaid and Sentry.",
    },
    {
      title: "5. Data Retention",
      content:
        "We retain your personal data only as long as necessary for the purposes described or as required by law.",
    },
    {
      title: "6. Your Rights",
      content:
        "Under the GDPR, you have the right to access, rectification, erasure, restriction, data portability, and objection.",
    },
    {
      title: "7. User Consent for Financial Data Processing",
      content:
        "By using our services, you explicitly consent to your tax advisor uploading your GDPdU data and processing your bank transactions for accounting purposes. This consent is required to fulfill the contractual obligations of the services.",
    },
    {
      title: "8. Security",
      content:
        "We use appropriate technical and organizational measures to protect your personal data from unauthorized access, misuse, or loss.",
    },
    {
      title: "9. Cookies and Tracking Technologies",
      content:
        "We use cookies and similar technologies to improve user experience. You can manage your cookie preferences through your browser settings.",
    },
    {
      title: "10. Automated Decision-Making and Profiling",
      content:
        "We do not use your personal data for automated decision-making that has legal or similar significant effects on you.",
    },
    {
      title: "11. Data Breach Notification",
      content:
        "In the event of a data breach, we will promptly inform you and the appropriate supervisory authorities in compliance with the GDPR.",
    },
    {
      title: "12. International Data Transfers",
      content:
        "Your personal data will not be transferred outside the European Economic Area (EEA).",
    },
    {
      title: "13. Changes to This Policy",
      content:
        "We may update this privacy policy from time to time. All changes will be posted on this page.",
    },
    {
      title: "14. Third-Party Links",
      content:
        "Our system does not contain third-party links. The only integration is Plaid for secure transaction retrieval.",
    },
    {
      title: "15. Contact Information",
      content:
        "If you have any questions about this privacy policy or how we handle your personal data, please contact us at support@kanzlei21.com.",
    },
  ],
};

export default function PrivacyPolicy() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <Card title={translations.title} className={styles.policyCard}>
      <section>
        <p className={styles.content}>
          <strong>{translations.effectiveDate}</strong>
        </p>
        <p className={styles.content}>
          <strong>{translations.lastUpdated}</strong>
        </p>
        <p className={styles.content}>{translations.welcomeMessage}</p>
      </section>

      <Divider />

      {translations.sections.map((section, index) => (
        <section key={index}>
          <h3>{section.title}</h3>
          <p className={styles.content}>{section.content}</p>
          <Divider />
        </section>
      ))}
    </Card>
  );
}
