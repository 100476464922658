import styles from "./edit-product-details.module.scss";
import { useId } from "react";
import { Document } from "../../../results/results-context";
import { Controller, useFieldArray, UseFormReturn } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { EditDocument } from "../edit-document-data-schemas";
import { Calendar } from "primereact/calendar";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  productDetails: "Produktdetails",
  item: "Artikel",
  productCode: "Produktcode",
  quantity: "Menge",
  amount: "Betrag",
  tax: "Steuer",
  unit: "Einheit",
  unitPrice: "Stückpreis",
  itemDate: "Artikeldatum",
};

const ENGLISH_TRANSLATIONS = {
  productDetails: "Product Details",
  item: "Item",
  productCode: "Product Code",
  quantity: "Quantity",
  amount: "Amount",
  tax: "Tax",
  unit: "Unit",
  unitPrice: "Unit Price",
  itemDate: "Item Date",
};

export function EditProductDetails(props: {
  selectedDocument: Document;
  form: UseFormReturn<EditDocument>;
}) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const idPrefix = useId();

  const { fields, append, remove } = useFieldArray({
    control: props.form.control,
    name: "changed.item_details",
  });

  return (
    <div className={styles.formField}>
      <label htmlFor={`${idPrefix}-item-details`} className={styles.label}>
        <b className={styles.fontSize1_1rem}>{translations.productDetails}</b>
      </label>

      {fields.map((field, index) => (
        <div key={field.id} className={styles.listedInputGroup}>
          <div className={styles.width100}>
            <label
              htmlFor={`${idPrefix}-item-${index}`}
              className={styles.label}
            >
              <b>
                {translations.item} #{index + 1}
              </b>
            </label>
            <Controller
              name={`changed.item_details.${index}.item_description`}
              control={props.form.control}
              render={({ field }) => (
                <InputText
                  id={`${idPrefix}-item-description-${index}`}
                  className={styles.input}
                  value={field.value || ""}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />

            <div>
              <label
                htmlFor={`${idPrefix}-product-code-${index}`}
                className={styles.label}
              >
                <b>{translations.productCode}</b>
              </label>
              <Controller
                name={`changed.item_details.${index}.product_code`}
                control={props.form.control}
                render={({ field }) => (
                  <InputText
                    id={`${idPrefix}-product-code-${index}`}
                    className={styles.input}
                    value={field.value || ""}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </div>

            <div className={styles.row}>
              <div className={styles.column}>
                <label
                  htmlFor={`${idPrefix}-item-quantity-${index}`}
                  className={styles.label}
                >
                  <b>{translations.quantity}</b>
                </label>
                <Controller
                  name={`changed.item_details.${index}.item_quantity`}
                  control={props.form.control}
                  render={({ field }) => (
                    <InputNumber
                      id={`${idPrefix}-item-quantity-${index}`}
                      value={field.value}
                      onValueChange={(e) => field.onChange(e.target.value)}
                      placeholder="Quantity"
                      inputClassName={styles.width100}
                      mode="decimal"
                      showButtons
                      minFractionDigits={1}
                      maxFractionDigits={3}
                      min={0}
                    />
                  )}
                />
              </div>
              <div className={styles.column}>
                <label
                  htmlFor={`${idPrefix}-amount-${index}`}
                  className={styles.label}
                >
                  <b>{translations.amount}</b>
                </label>
                <Controller
                  name={`changed.item_details.${index}.amount`}
                  control={props.form.control}
                  render={({ field }) => (
                    <InputNumber
                      id={`${idPrefix}-amount-${index}`}
                      value={field.value}
                      onValueChange={(e) => field.onChange(e.target.value)}
                      placeholder="Amount"
                      inputClassName={styles.width100}
                      mode="decimal"
                      showButtons
                      minFractionDigits={1}
                      maxFractionDigits={3}
                      min={0}
                    />
                  )}
                />
              </div>
              <div className={styles.column}>
                <label
                  htmlFor={`${idPrefix}-tax-${index}`}
                  className={styles.label}
                >
                  <b>{translations.tax}</b>
                </label>
                <Controller
                  name={`changed.item_details.${index}.tax`}
                  control={props.form.control}
                  render={({ field }) => (
                    <InputNumber
                      id={`${idPrefix}-tax-${index}`}
                      value={field.value}
                      onValueChange={(e) => field.onChange(e.target.value)}
                      placeholder="Amount"
                      inputClassName={styles.width100}
                      mode="decimal"
                      showButtons
                      minFractionDigits={1}
                      maxFractionDigits={3}
                      min={0}
                      suffix="%"
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.column}>
                <label
                  htmlFor={`${idPrefix}-unit-${index}`}
                  className={styles.label}
                >
                  <b>{translations.unit}</b>
                </label>
                <Controller
                  name={`changed.item_details.${index}.unit`}
                  control={props.form.control}
                  render={({ field }) => (
                    <InputText
                      id={`${idPrefix}-unit-${index}`}
                      className={styles.input}
                      value={field.value || ""}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </div>
              <div className={styles.column}>
                <label
                  htmlFor={`${idPrefix}-unit-price-${index}`}
                  className={styles.label}
                >
                  <b>{translations.unitPrice}</b>
                </label>
                <Controller
                  name={`changed.item_details.${index}.unit_price`}
                  control={props.form.control}
                  render={({ field }) => (
                    <InputNumber
                      id={`${idPrefix}-unit-price-${index}`}
                      value={field.value}
                      onValueChange={(e) => field.onChange(e.target.value)}
                      placeholder="Unit Price"
                      inputClassName={styles.width100}
                      mode="decimal"
                      showButtons
                      minFractionDigits={1}
                      maxFractionDigits={3}
                      min={0}
                    />
                  )}
                />
              </div>
              <div className={styles.column}>
                <label
                  htmlFor={`${idPrefix}-item-date-${index}`}
                  className={styles.label}
                >
                  <b>{translations.itemDate}</b>
                </label>
                <Controller
                  name={`changed.item_details.${index}.item_date`}
                  control={props.form.control}
                  render={({ field }) => (
                    <Calendar
                      id={`${idPrefix}-item-date-${index}`}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      dateFormat="dd MM yy"
                      showIcon
                      showButtonBar
                      className={styles.width100}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <Button
            icon="pi pi-trash"
            text
            className={`${styles.marginSpacer} p-button-danger`}
            onClick={() => {
              remove(index);
            }}
            size="large"
          />
        </div>
      ))}

      <Button
        className={styles.width100}
        severity={"secondary"}
        icon="pi pi-plus"
        type="button"
        size="large"
        onClick={() => {
          append({
            item_description: null,
            item_quantity: null,
            unit: null,
            unit_price: null,
            currency: "EUR",
            product_code: null,
            tax: null,
            amount: null,
            item_date: null,
          });
        }}
      />
    </div>
  );
}
