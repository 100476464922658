import styles from "./quick-filters.module.scss";
import { Calendar } from "primereact/calendar";
import { useContext } from "react";
import { QuickFiltersContext } from "./quick-filters-context";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { throwError } from "../../../../../../throw-error";

const GERMAN_TRANSLATIONS = {
    from: "Von",
    to: "Bis",
};

const ENGLISH_TRANSLATIONS = {
    from: "From",
    to: "To",
};

export function QuickFilters() {
    const { quickFilters, setQuickFilters } =
        useContext(QuickFiltersContext) || throwError();

    const currentLanguage = useCurrentLanguage();
    const translations =
        currentLanguage === SupportedLanguage.German
            ? GERMAN_TRANSLATIONS
            : ENGLISH_TRANSLATIONS;

    return (
        <div className={styles.buttonBar}>
            <div className={styles.buttonGroup}>
                {/* "From" calendar input */}
                <Calendar
                    value={quickFilters.time_frame_from}
                    onChange={(e) => {
                        setQuickFilters((prev) => ({
                            ...prev,
                            time_frame_from: e.value ?? undefined,
                        }));
                    }}
                    dateFormat="dd MM yy"
                    placeholder={translations.from}
                    showIcon
                    showButtonBar
                />
                {/* "To" calendar input */}
                <Calendar
                    value={quickFilters.time_frame_to}
                    onChange={(e) => {
                        setQuickFilters((prev) => ({
                            ...prev,
                            time_frame_to: e.value ?? undefined,
                        }));
                    }}
                    dateFormat="dd MM yy"
                    placeholder={translations.to}
                    showIcon
                    showButtonBar
                />
            </div>
        </div>
    );
}
