import {
  useEffect,
  useContext,
  useRef,
  useMemo,
  useId,
  useCallback,
} from "react";
import styles from "./edit-document-data.module.scss";
import {
  ResultsContext,
  Document,
  DocumentSchema,
} from "../../results/results-context";
import { throwError } from "../../../../../../../throw-error";
import { z } from "zod";
import { EditDocumentSchema, EditDocument } from "./edit-document-data-schemas";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMainApi } from "../../../../../../../main-api";
import { InputText } from "primereact/inputtext";
import { EditIbanList } from "./edit-ibans/edit-ibans";
import { EditGeneralInformation } from "./edit-general-information/edit-general-information";
import { EditProductDetails } from "./edit-product-details/edit-product-details";
import { EditVatInclusiveOverview } from "./edit-vat-inclusive-overview/edit-vat-inclusive-overview";
import { useAccountingClientId } from "../../../accounting-client-id";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../../communication-errors/communication-error-messages";
import { Message } from "primereact/message";
import { toDateString } from "../../../../../../../dates";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  ibanValidationMessage:
    "Bitte geben Sie eine gültige IBAN ein oder entfernen Sie diesen Eintrag",
  vatRateValidationMessage:
    "Bitte geben Sie einen Wert über 0 ein oder entfernen Sie diesen Eintrag",
  vatAmountValidationMessage:
    "Bitte geben Sie einen Wert über 0 ein oder entfernen Sie diesen Eintrag",
  documentNameMessage: "Bitte geben Sie einen Dokumentnamen ein",
  formInvalidStateMessage:
    "Bitte füllen Sie alle erforderlichen Felder aus, um Ihre Änderungen zu übermitteln.",
  documentIssues: "Dokumentprobleme",
  reviewNeeded: "Überprüfung erforderlich",
  invalidIbansTitle: "Ungültige IBANs",
  invalidIbansDescription:
    "Einige IBANs im Dokument sind ungültig und sollten korrigiert werden.",
};

const ENGLISH_TRANSLATIONS = {
  formInvalidStateMessage:
    "Please fill out all required fields to submit your changes.",
  documentIssues: "Document Issues",
  reviewNeeded: "Review needed",
  invalidIbansTitle: "Invalid IBANs",
  invalidIbansDescription:
    "Some of the IBANs in the document are invalid and need to be corrected.",
  ibanValidationMessage: "Please enter a valid IBAN or remove this entry",
  vatRateValidationMessage: "Please enter a value above 0 or remove this entry",
  vatAmountValidationMessage:
    "Please enter a value above 0 or remove this entry",
  documentNameMessage: "Please enter a document name",
};

export function EditDocumentData(props: { selectedDocument: Document }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const mainApi = useMainApi();

  const idPrefix = useId();

  const accountingClientId = useAccountingClientId();

  const { setResults } = useContext(ResultsContext) || throwError();

  const toastRef = useRef<Toast>(null);

  const defaultValues: EditDocument = useMemo(() => {
    return {
      document_name: props.selectedDocument.name,
      changed: {
        document_type: props.selectedDocument.document_data.document_type,
        general_information: {
          date:
            props.selectedDocument.document_data.general_information.date ??
            null,
          due_date:
            props.selectedDocument.document_data.general_information.due_date ??
            null,
          total_amount:
            props.selectedDocument.document_data.general_information
              .total_amount ?? null,
          subtotal:
            props.selectedDocument.document_data.general_information.subtotal ??
            null,
          currency:
            props.selectedDocument.document_data.general_information.currency ??
            null,
          iban_list:
            props.selectedDocument.document_data.general_information.iban_list.map(
              (iban) => ({ iban: iban.iban })
            ),
          vat_amounts:
            props.selectedDocument.document_data.general_information
              .vat_amounts,
          invoice_number:
            props.selectedDocument.document_data.general_information
              .invoice_number ?? null,
          client_source_name:
            props.selectedDocument.document_data.general_information
              .client_source_name ?? null,
          client_source_address:
            props.selectedDocument.document_data.general_information
              .client_source_address ?? null,
          client_destination_name:
            props.selectedDocument.document_data.general_information
              .client_destination_name ?? null,
          client_destination_address:
            props.selectedDocument.document_data.general_information
              .client_destination_address ?? null,
          client_source_vat_id:
            props.selectedDocument.document_data.general_information
              .client_source_vat_id ?? null,
          client_source_tax_number:
            props.selectedDocument.document_data.general_information
              .client_source_tax_number ?? null,
          payment_terms:
            props.selectedDocument.document_data.general_information
              .payment_terms ?? null,
        },
        item_details:
          props.selectedDocument.document_data.item_details.map((item) => ({
            item_description: item.item_description ?? null,
            item_quantity: item.item_quantity ?? null,
            item_date: item.item_date ?? null,
            unit: item.unit ?? null,
            unit_price: item.unit_price ?? null,
            currency: item.currency ?? null,
            product_code: item.product_code ?? null,
            tax: item.tax ?? null,
            amount: item.amount ?? null,
          })) ?? [],
      },
    };
  }, [props.selectedDocument]);

  const form = useForm<EditDocument>({
    resolver: zodResolver(EditDocumentSchema(translations)),
    defaultValues: defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  const serializedFormValue = JSON.stringify(form.watch());

  const handleSubmit = useCallback(() => {
    form.handleSubmit(async (formData: EditDocument) => {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append("accounting_client_id", accountingClientId);

      const res = await mainApi.fetchJSON({
        path: `/documents/accounting-dashboard/${props.selectedDocument.id}?${urlSearchParams.toString()}`,
        method: "PUT",
        body: {
          document_name: formData.document_name,
          changed: {
            document_type: formData.changed.document_type,
            general_information: {
              ...formData.changed.general_information,
              date: formData.changed.general_information.date
                ? toDateString(formData.changed.general_information.date)
                : null,
              due_date: formData.changed.general_information.due_date
                ? toDateString(formData.changed.general_information.due_date)
                : null,

              iban_list: formData.changed.general_information.iban_list?.map(
                (item) => item.iban
              ),

              vat_amount:
                formData.changed.general_information.vat_amounts.reduce(
                  (acc: { [key: number]: number[] }, item) => {
                    return {
                      ...acc,
                      [item.vat_rate]: [
                        ...(acc[item.vat_rate] || []),
                        item.vat_amount,
                      ],
                    };
                  },
                  {}
                ),
            },
            item_details: formData.changed.item_details.map((detail) => ({
              ...detail,
              item_date: detail.item_date
                ? toDateString(detail.item_date)
                : null,
            })),
          },
        },
        schema: z.object({
          status: z.literal(200),
          body: DocumentSchema,
        }),
      });

      if (res.error) {
        toastRef.current?.show({
          severity: "error",
          summary: getCommunicationErrorTitle(res.error),
          detail: getCommunicationErrorMessage(res.error),
        });
        return;
      }

      setResults((results) => ({
        ...results,
        data: results.data && {
          ...results.data,
          rows: results.data.rows.map((d) =>
            d.id === props.selectedDocument.id ? res.response.body : d
          ),
        },
      }));
    })();
  }, [
    accountingClientId,
    form,
    mainApi,
    props.selectedDocument.id,
    setResults,
  ]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      handleSubmit();
    }, 1000);

    return () => clearTimeout(debounceTimer);
  }, [serializedFormValue, handleSubmit]);

  return (
    <>
      <Toast ref={toastRef} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={styles.formLayout}>
          <div className={styles.stickyField}>
            <div className={styles.formSection}>
              <Controller
                name="document_name"
                control={form.control}
                render={({ field, fieldState }) => (
                  <div
                    className={`${styles.width100} ${styles.marginBottomSpacer}`}
                  >
                    <InputText
                      id={`${idPrefix}-document-name`}
                      className={`${styles.input} ${fieldState.error ? "p-invalid" : ""}`}
                      value={field.value}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                    {fieldState.error && (
                      <small className={styles.errorMessage}>
                        {fieldState.error.message}
                      </small>
                    )}
                  </div>
                )}
              />
              <div className={styles.spinnerField}>
                <div>
                  {form.formState.isSubmitting && (
                    <i
                      className="pi pi-spin pi-spinner"
                      style={{ fontSize: "2rem" }}
                    ></i>
                  )}
                </div>
              </div>
            </div>
            {!form.formState.isValid && (
              <div className={styles.marginBottomSpacer}>
                <Message
                  severity="error"
                  text={translations.formInvalidStateMessage}
                  className={styles.width100}
                />
              </div>
            )}
            {props.selectedDocument.issues.length > 0 && (
              <div className={styles.marginBottomSpacer}>
                <Message
                  severity="warn"
                  content={
                    <div className={styles.messageContent}>
                      <b>{translations.documentIssues}:</b>
                      <ul>
                        {props.selectedDocument.issues.map((issue, index) => {
                          if (issue.type === "review_needed") {
                            return (
                              <li key={index}>
                                <span>
                                  {translations.reviewNeeded}:{" "}
                                  {issue.description}
                                </span>
                              </li>
                            );
                          } else if (issue.type === "invalid_ibans") {
                            return (
                              <li key={index}>
                                <span>
                                  {translations.invalidIbansDescription}:{" "}
                                  {translations.invalidIbansDescription}
                                </span>
                              </li>
                            );
                          } else {
                            throw new Error();
                          }
                        })}
                      </ul>
                    </div>
                  }
                  className={styles.width100}
                />
              </div>
            )}
          </div>

          <div className={`${styles.formSection} ${styles.marginBottomSpacer}`}>
            <EditGeneralInformation
              selectedDocument={props.selectedDocument}
              form={form}
            />
          </div>

          <div
            className={`${styles.formSection} ${styles.marginBottomDoubleSpacer}`}
          >
            <EditIbanList form={form} />
          </div>

          <div
            className={`${styles.formSection} ${styles.marginBottomDoubleSpacer}`}
          >
            <EditProductDetails
              selectedDocument={props.selectedDocument}
              form={form}
            />
          </div>

          <div
            className={`${styles.formSection} ${styles.marginBottomDoubleSpacer}`}
          >
            <EditVatInclusiveOverview form={form} />
          </div>
        </div>
      </form>
    </>
  );
}
