import { useNavigate } from "react-router-dom";
import { Menu as PrimeReactMenu } from "primereact/menu";
import styles from "./menu.module.scss";
import { AccountingClientMenu } from "./accounting-client-menu";

export function Menu() {
  const navigate = useNavigate();

  return (
    <>
      <PrimeReactMenu
        className={`${styles.primeReactMenu}`}
        model={[
          {
            label: "Dashboard",
            icon: "pi pi-home",
            className: `${window.location.pathname == "/client" || window.location.pathname == "/client/" ? "p-menuitem-active" : ""}`,
            command: () => {
              navigate("/client");
            },
          },
        ]}
      />
      <hr className={styles.divider} />
      <AccountingClientMenu />
    </>
  );
}
