import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { useContext, useEffect, useRef } from "react";
import styles from "./filters.module.scss";
import { FiltersContext, FiltersSchema, FiltersType } from "./filters-context";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, Controller } from "react-hook-form";
import { throwError } from "../../../../../../throw-error";
import { InputText } from "primereact/inputtext";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { Dropdown } from "primereact/dropdown";

const GERMAN_TRANSLATIONS = {
  documentName: "Dokumentname",
  showIssuesOnly: "Nur Probleme anzeigen",
  all: "Alle",
  withIssues: "Mit Problemen",
  withoutIssues: "Ohne Probleme",
  search: "Suchen",
  filtersTooltip: "Filter",
};

const ENGLISH_TRANSLATIONS = {
  documentName: "Document Name",
  all: "All",
  withIssues: "With Issues",
  withoutIssues: "Without Issues",
  search: "Search",
  filtersTooltip: "Filters",
};

function _FiltersPanel() {
  const { filters, setFilters } = useContext(FiltersContext) || throwError();

  const form = useForm<FiltersType>({
    resolver: zodResolver(FiltersSchema),
    mode: "onChange",
    defaultValues: filters,
  });

  useEffect(() => {
    form.reset(filters);
  }, [filters, form]);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const onSubmit = (values: FiltersType) => {
    setFilters(values);
  };

  return (
    <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
      <div className={styles.filter}>
        <b className={styles.filterLabel}>{translations.documentName}</b>
        <Controller
          name="search"
          control={form.control}
          render={({ field }) => {
            return (
              <div className={`p-inputgroup ${styles.inputGroup}`}>
                <InputText
                  className={styles.input}
                  id="name"
                  value={field.value || ""}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </div>
            );
          }}
        />
      </div>

      <div className={styles.filter}>
        <Controller
          name="issues"
          control={form.control}
          render={({ field }) => {
            return (
              <div className={`p-inputgroup ${styles.inputGroup}`}>
                <Dropdown
                  className={styles.input}
                  options={[
                    { label: translations.all, value: "all" },
                    { label: translations.withIssues, value: true },
                    { label: translations.withoutIssues, value: false },
                  ]}
                  value={field.value ?? "all"}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value === "all" ? null : e.target.value
                    )
                  }
                />
              </div>
            );
          }}
        />
      </div>

      <Button
        type="submit"
        className={styles.width100}
        icon="pi pi-search"
        label={translations.search}
      />
    </form>
  );
}

export function Filters() {
  const filtersOverlayPanel = useRef<null | OverlayPanel>(null);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <>
      <Button
        icon="pi pi-sliders-h"
        onClick={(e) => filtersOverlayPanel.current?.toggle(e)}
        tooltip={translations.filtersTooltip}
        severity="secondary"
      />
      <OverlayPanel ref={filtersOverlayPanel}>
        <_FiltersPanel />
      </OverlayPanel>
    </>
  );
}
