import { Document } from "../../results/results-context";
import { PdfFilePreview } from "./pdf-file-preview";
import { DocumentPagesPreview } from "./document-pages-preview";

export function DocumentPreview(props: { document: Document }) {
  if (props.document.document_upload.type == "desktop") {
    return <PdfFilePreview document={props.document} />;
  } else if (props.document.document_upload.type == "mobile") {
    return <DocumentPagesPreview document={props.document} />;
  } else {
    throw new Error();
  }
}
