import { Route, Routes, BrowserRouter } from "react-router-dom";
import { AccountantAuthenticationStateProvider } from "./accountants/authentication/authentication-state";
import { PrimeReactProvider } from "primereact/api";
import { AccountantAuthEffects } from "./accountants/authentication/authentication-effects";
import HomePage from "./app/accountant/page";
import LoginPage from "./app/accountant/login/page";
import { AccountantUserForgotPassword } from "./app/accountant/forgot-password/forgot-password";
import { AccountantUserResetPassword } from "./app/accountant/reset-password/reset-password";
import Layout from "./app/accountant/layout";
import { Layout as ClientLayout } from "./app/client/layout";
import { AccountantUserSettings } from "./app/accountant/settings/page";
import EditClientsPage from "./app/accountant/accounting-clients/accounting-client-id/edit/page";
import { ClientAuthEffects } from "./accounting-clients/authentication/authentication-effects";
import { ClientAuthenticationStateProvider } from "./accounting-clients/authentication/authentication-state";
import { Outlet } from "react-router-dom";
import { ClientLoginPage } from "./app/client/login/page";
import { ClientUserForgotPassword } from "./app/client/forgot-password/forgot-password";
import { ClientUserResetPassword } from "./app/client/reset-password/page";
import { ClientHomePage } from "./app/client/page";
import { AccountantAuthenticatedPage } from "./accountants/authentication/authenticated-page";
import { ClientAuthenticatedPage } from "./accounting-clients/authentication/authenticated-page";
import { AcceptInvitePage } from "./app/client/accept-invite/page";
import { BookingsPage } from "./app/accountant/accounting-clients/accounting-client-id/bookings/page";
import { AccountingFirmPage } from "./app/accountant/accounting-firm/page";
import { DocumentsPage as AccountingClientDocumentsPage } from "./app/client/documents/page";
import BankTransactionsPage from "./app/accountant/accounting-clients/accounting-client-id/bank-transactions/page";
import { NotFoundPage } from "./app/not-found/page";
import PrivacyPolicy from "./app/client/privacy-policy/privacy-policy";
import TermsAndConditions from "./app/client/terms-and-conditions/terms_and_conditions";
import { AccountingClientsProvider } from "./app/accountant/menu/accounting-clients-context";
import { DocumentsPage } from "./app/accountant/accounting-clients/accounting-client-id/documents/page";
import HelpSupportPage from "./app/accountant/help-support/help-support";
import HowToExportBookingsToEdrewePage from "./app/accountant/help-support/export-bookings-to-edrewe/export-bookings-to-edrewe";

function App() {
  return (
    <AccountantAuthenticationStateProvider>
      <ClientAuthenticationStateProvider>
        <PrimeReactProvider>
          <AccountantAuthEffects />
          <ClientAuthEffects />
          <BrowserRouter>
            <Routes>
              <Route path="/accountant">
                <Route path="login" element={<LoginPage />} />
                <Route
                  path="forgot-password"
                  element={<AccountantUserForgotPassword />}
                />
                <Route
                  path="reset-password"
                  element={<AccountantUserResetPassword />}
                />
                <Route
                  element={
                    <AccountantAuthenticatedPage>
                      <AccountingClientsProvider>
                        <Layout>
                          <Outlet />
                        </Layout>
                      </AccountingClientsProvider>
                    </AccountantAuthenticatedPage>
                  }
                >
                  <Route index element={<HomePage />} />
                  <Route path="settings" element={<AccountantUserSettings />} />

                  <Route path="help-support">
                    <Route index element={<HelpSupportPage />} />
                    <Route
                      path="export-bookings-to-edrewe"
                      element={<HowToExportBookingsToEdrewePage />}
                    />
                  </Route>

                  <Route path="accounting-clients">
                    <Route path="create/*" element={<EditClientsPage />} />
                    <Route path=":accountingClientId">
                      <Route path="edit/*" element={<EditClientsPage />} />
                      {/* <Route path="invoices" element={<InvoicesPage />} /> */}
                      <Route
                        path="bank-transactions"
                        element={<BankTransactionsPage />}
                      />
                      <Route path="bank-bookings" element={<BookingsPage />} />
                      <Route path="documents" element={<DocumentsPage />} />
                    </Route>
                  </Route>
                  <Route
                    path="accounting-firm"
                    element={<AccountingFirmPage />}
                  />
                </Route>
              </Route>
              <Route path="/client">
                <Route
                  element={
                    <ClientAuthenticatedPage>
                      <ClientLayout>
                        <Outlet />
                      </ClientLayout>
                    </ClientAuthenticatedPage>
                  }
                >
                  <Route index element={<ClientHomePage />} />
                  <Route
                    path="documents"
                    element={<AccountingClientDocumentsPage />}
                  />
                </Route>

                <Route path="login" element={<ClientLoginPage />} />
                <Route
                  path="forgot-password"
                  element={<ClientUserForgotPassword />}
                />
                <Route
                  path="reset-password"
                  element={<ClientUserResetPassword />}
                />
                <Route path="accept-invite" element={<AcceptInvitePage />} />

                <Route path="privacy-policy" element={<PrivacyPolicy />} />

                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </BrowserRouter>
        </PrimeReactProvider>
      </ClientAuthenticationStateProvider>
    </AccountantAuthenticationStateProvider>
  );
}

export default App;
