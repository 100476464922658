import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMainApi } from "../../../main-api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { Card } from "primereact/card";
import styles from "./loginPage.module.scss";
import { CommunicationError } from "../../../communication-errors/communication-errors";
import { getCommunicationErrorMessage } from "../../../communication-errors/communication-error-messages";
import { useNavigate } from "react-router-dom";
import { AccountantAuthenticationStateContext } from "../../../accountants/authentication/authentication-state";
import { throwError } from "../../../throw-error";
import { AccountantSessionSchema } from "../../../accountants/authentication/authentication-schemas";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  email: "E-Mail",
  password: "Passwort",
  signIn: "Anmelden",
  wrongCredentials:
    "Die eingegebene E-Mail-Adresse oder das Passwort ist falsch. Bitte versuchen Sie es erneut.",
  codePrompt:
    "Bitte geben Sie den Code ein, der an Ihre E-Mail-Adresse gesendet wurde.",
  resendCode: "Code erneut senden",
  verifyCode: "Code bestätigen",
  invalidCodeError:
    "Der eingegebene Code ist ungültig. Bitte geben Sie einen gültigen Code ein.",
  forgotPassword: "Passwort vergessen?",
  didntReceiveCode: "Code nicht erhalten?",
  emailRequired: "E-Mail ist erforderlich",
  emailInvalid: "Ungültige E-Mail-Adresse",
  passwordRequired: "Passwort ist erforderlich",
};

const ENGLISH_TRANSLATIONS = {
  email: "Email",
  password: "Password",
  signIn: "Sign In",
  wrongCredentials:
    "The email or password you entered is incorrect. Please try again.",
  codePrompt: "Please enter the code sent to your email.",
  resendCode: "Resend Code",
  verifyCode: "Verify Code",
  invalidCodeError:
    "The code entered is invalid. Please enter a valid code sent to your email.",
  forgotPassword: "Forgot password?",
  didntReceiveCode: "Didn't receive the code?",
  emailRequired: "Email is required",
  emailInvalid: "Invalid email address",
  passwordRequired: "Password is required",
};

export default function LoginPage() {
  const navigate = useNavigate();
  const mainApi = useMainApi();
  const { sessionState, setSessionState } =
    useContext(AccountantAuthenticationStateContext) || throwError();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const GenerateTOTPFormSchema = z.object({
    email: z
      .string()
      .min(1, translations.emailRequired)
      .trim()
      .toLowerCase()
      .email(translations.emailInvalid),
    password: z.string().min(1, translations.passwordRequired),
  });

  const LoginFormSchema = z.object({
    email: z
      .string()
      .min(1, translations.emailRequired)
      .trim()
      .toLowerCase()
      .email(translations.emailInvalid),
    password: z.string().min(1, translations.passwordRequired),
    time_based_one_time_password_code: z.string().trim(),
  });

  type TLoginFormSchema = z.infer<typeof LoginFormSchema>;
  type TGenerateTOTPFormSchema = z.infer<typeof GenerateTOTPFormSchema>;

  const [loading, setLoading] = useState(false);
  const [actionInProgress, setActionInProgress] = useState<
    "sendOtp" | "login" | null
  >(null);
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState<
    undefined | CommunicationError | "wrong-credentials" | "invalid-code"
  >();

  const currentSchema = otpSent ? LoginFormSchema : GenerateTOTPFormSchema;

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<TLoginFormSchema>({
    resolver: zodResolver(currentSchema),
    defaultValues: {
      email: "",
      password: "",
      time_based_one_time_password_code: "",
    },
  });

  useEffect(() => {
    if (sessionState.data) {
      navigate("/accountant");
    }
  }, [sessionState, navigate]);

  const handleLogin = async (data: TLoginFormSchema) => {
    setError(undefined);
    setLoading(true);
    setActionInProgress("login");

    const res = await mainApi.fetchJSON({
      method: "POST",
      path: "/accountant_users/login",
      schema: z.union([
        z.object({
          status: z.literal(404),
          body: z
            .object({
              detail: z.literal("invalid-totp-code"),
            })
            .nullable(),
        }),
        z.object({
          status: z.literal(200),
          body: z.object({
            session: AccountantSessionSchema,
          }),
        }),
      ]),
      body: {
        email: data.email,
        password: data.password,
        time_based_one_time_password_code:
          data.time_based_one_time_password_code,
      },
    });

    setLoading(false);
    setActionInProgress(null);

    if (res.error) {
      setError(res.error);
      return;
    }

    if (res.response.status === 404) {
      if (res.response.body?.detail === "invalid-totp-code") {
        setError("invalid-code");
        return;
      }

      setError("wrong-credentials");
      return;
    }

    setSessionState({ data: res.response.body.session });
  };

  const handleSendOtp = async (data: TGenerateTOTPFormSchema) => {
    setError(undefined);
    setLoading(true);
    setActionInProgress("sendOtp");

    const res = await mainApi.fetchJSON({
      method: "POST",
      path: "/accountant_users/generate_totp",
      schema: z.union([
        z.object({
          status: z.literal(404),
        }),
        z.object({
          status: z.literal(401),
        }),
        z.object({
          status: z.literal(200),
        }),
      ]),
      body: { email: data.email, password: data.password },
    });

    setLoading(false);
    setActionInProgress(null);

    if (res.error) {
      setError(res.error);
      return;
    }

    if (res.response.status === 404) {
      setError("wrong-credentials");
      return;
    }

    if (res.response.status === 200) {
      setOtpSent(true);
    }
  };

  const onSubmit = otpSent ? handleLogin : handleSendOtp;

  return (
    <main className={styles.loginWrapper}>
      <Card className={styles.loginContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.formField}>
            <label htmlFor="email" className={styles.formFieldLabel}>
              <b>{translations.email}</b>
            </label>
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <InputText
                  id={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  className={classNames(styles.formFieldInput, {
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            {errors.email && (
              <small className={styles.formFieldError}>
                {errors.email.message}
              </small>
            )}
          </div>

          <div className={styles.formField}>
            <label htmlFor="password" className={styles.formFieldLabel}>
              <b>{translations.password}</b>
            </label>
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <Password
                  id={field.name}
                  value={field.value}
                  onChange={field.onChange}
                  toggleMask
                  feedback={false}
                  className={styles.formFieldInput}
                  inputClassName={classNames(styles.width100Percent, {
                    "p-invalid": fieldState.invalid,
                  })}
                />
              )}
            />
            {errors.password && (
              <small className={styles.formFieldError}>
                {errors.password.message}
              </small>
            )}
          </div>

          {otpSent && (
            <div className={styles.formField}>
              <label
                htmlFor="time_based_one_time_password_code"
                className={styles.formFieldLabel}
              >
                <b>{translations.codePrompt}</b>
              </label>
              <Controller
                name="time_based_one_time_password_code"
                control={control}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    className={classNames(styles.formFieldInput, {
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {errors.time_based_one_time_password_code && (
                <small className={styles.formFieldError}>
                  {errors.time_based_one_time_password_code.message}
                </small>
              )}
            </div>
          )}

          {(() => {
            if (error) {
              if (error === "wrong-credentials") {
                return (
                  <div className={styles.errorMessage}>
                    <small>{translations.wrongCredentials}</small>
                  </div>
                );
              } else if (error === "invalid-code") {
                return (
                  <div className={styles.errorMessage}>
                    <small>{translations.invalidCodeError}</small>
                  </div>
                );
              } else {
                return (
                  <div className={styles.errorMessage}>
                    <small>{getCommunicationErrorMessage(error)}</small>
                  </div>
                );
              }
            }
          })()}
          {otpSent ? (
            <div>
              <div className={styles.buttonsWrapper}>
                <span className={styles.resendLabel}>
                  {translations.didntReceiveCode}
                </span>
                <Button
                  loading={loading && actionInProgress === "sendOtp"}
                  disabled={loading}
                  label={translations.resendCode}
                  type="button"
                  onClick={handleSubmit(handleSendOtp)}
                  className={styles.resendButton}
                />
              </div>
              <Button
                type="submit"
                loading={loading && actionInProgress === "login"}
                disabled={loading}
                label={translations.verifyCode}
                onClick={handleSubmit(handleLogin)}
                className={styles.submitButton}
              />
            </div>
          ) : (
            <Button
              loading={loading}
              disabled={loading}
              label={
                otpSent
                  ? `${translations.verifyCode}`
                  : `${translations.signIn}`
              }
              type="submit"
              className={styles.submitButton}
            />
          )}
        </form>
        <div className={styles.flexJustifyEnd}>
          <Button
            disabled={loading}
            label={translations.forgotPassword}
            onClick={() => navigate("/accountant/forgot-password")}
            link
          />
        </div>
      </Card>
    </main>
  );
}
