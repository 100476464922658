import styles from "./column-2.module.scss";
import { Booking } from "../../results/results-context";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  issuesTitle: "Probleme",
  unreliablePredictionTitle: "Keine zuverlässige Vorhersage möglich",
  couldNotPredictTaxCodeTitle: "Kann Steuercodes nicht vorhersagen",
  multipleTaxCodesTitle: "Mehrere Steuercodes",
  multipleTaxCodesDescription:
    "Es gibt viele moegliche Steuercodes für diese Buchung:",
};

const ENGLISH_TRANSLATIONS = {
  issuesTitle: "Issues",
  unreliablePredictionTitle: "Unable to make a reliable prediction",
  couldNotPredictTaxCodeTitle: "Could not predict tax code",
  multipleTaxCodesTitle: "Multiple Tax Codes",
  multipleTaxCodesDescription:
    "There are many possible tax codes for this booking:",
};

export function Column2(props: { selectedBooking: Booking }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.container}>
      {props.selectedBooking.issues.length > 0 && (
        <ul className={styles.listStylePositionInside}>
          {props.selectedBooking.issues.map((issue) => {
            return (
              <li key={issue.id}>
                {(() => {
                  if (issue.type === "COULD_NOT_PREDICT_TAX_CODE") {
                    return <b>{translations.couldNotPredictTaxCodeTitle}</b>;
                  } else if (issue.type === "MULTIPLE_TAX_CODES") {
                    return <b>{translations.multipleTaxCodesTitle}</b>;
                  } else if (issue.type === "UNRELIABLE_PREDICTION") {
                    return <b>{translations.unreliablePredictionTitle}</b>;
                  } else {
                    throw new Error();
                  }
                })()}
              </li>
            );
          })}
        </ul>
      )}
      {props.selectedBooking.prediction_reasoning && (
        <p>{props.selectedBooking.prediction_reasoning}</p>
      )}
      {props.selectedBooking.tax_code_prediction_reasoning && (
        <p>{props.selectedBooking.tax_code_prediction_reasoning}</p>
      )}
    </div>
  );
}
