import { Button } from "primereact/button";
import styles from "./actions.module.scss";
import { useContext } from "react";
import { throwError } from "../../../../../../../throw-error";
import { Booking, ResultsContext } from "../../results/results-context";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  cancel: "Abbrechen",
  apply: "Anwenden",
};

const ENGLISH_TRANSLATIONS = {
  cancel: "Cancel",
  apply: "Apply",
};

export function Actions({
  selectedBooking,
  isSubmitting,
}: {
  selectedBooking: Booking;
  isSubmitting: boolean;
}) {
  const { setSelectedBookingId } = useContext(ResultsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.actionBar}>
      <Button
        icon="pi pi-times"
        severity="danger"
        label={translations.cancel}
        type="button"
        onClick={() => setSelectedBookingId(undefined)}
      />
      <Button
        icon="pi pi-check"
        severity="success"
        label={translations.apply}
        className={styles.actionButton}
        disabled={isSubmitting || selectedBooking.status == "HISTORICAL"}
        loading={isSubmitting}
        type="submit"
      />
    </div>
  );
}
