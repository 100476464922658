import { Menu as PrimeReactMenu } from "primereact/menu";
import styles from "./menu.module.scss";
import { AccountingClients } from "./accounting-clients";
import { useNavigate } from "react-router-dom";
import { AccountingClientMenu } from "./accounting-client-menu";
import { useCurrentLanguage } from "../../../language/current-language";
import { SupportedLanguage } from "../../../language/supported-languages";
import { HELP_SUPPORT_ROUTES } from "../help-support/help-support-route";

const GERMAN_TRANSLATIONS = {
  dashboard: "Dashboard",
  helpSupport: "Hilfe & Support",
};

const ENGLISH_TRANSLATIONS = {
  dashboard: "Dashboard",
  helpSupport: "Help & Support",
};

export function Menu() {
  const navigate = useNavigate();
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.container}>
      <div>
        <PrimeReactMenu
          className={`${styles.primeReactMenu}`}
          model={[
            {
              label: translations.dashboard,
              icon: "pi pi-home",
              className: `${window.location.pathname == "/accountant" || window.location.pathname == "/accountant/" ? "p-menuitem-active" : ""}`,
              command: () => {
                navigate("/accountant");
              },
            },
          ]}
        />
        <hr className={styles.divider} />
        <div className={styles.section}>
          <AccountingClients />
        </div>
        <AccountingClientMenu />
      </div>
      <PrimeReactMenu
        className={styles.primeReactMenu}
        model={[
          {
            label: `🛟 ${translations.helpSupport}`,
            className: `${window.location.pathname.startsWith(HELP_SUPPORT_ROUTES.path) ? "p-menuitem-active" : ""}`,
            command: () => {
              navigate(HELP_SUPPORT_ROUTES.getHref());
            },
          },
        ]}
      />
    </div>
  );
}
