import { useState, useRef, useEffect, useMemo, useContext } from "react";
import { useAccountingClientApi } from "./accountingClientApi";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import styles from "./basic-information.module.scss";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { AccountingClient, FormOptions } from "../accountingClientLoader";
import { MainApiFetchJSONResponse } from "../../../../../../main-api";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../communication-errors/communication-error-messages";
import { ClientData } from "./form-sections/client-data";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { useNavigate } from "react-router-dom";
import { EDIT_CLIENTS_ROUTES } from "../edit-clients-routes";
import { ContactInformation } from "./form-sections/contact-information";
import { AccountingClientsContext } from "../../../../menu/accounting-clients-context";
import { throwError } from "../../../../../../throw-error";

const GERMAN_TRANSLATIONS = {
  save: "Speichern",
  success: "Erfolg",
  successDetail: "Buchhaltungsmandant erfolgreich gespeichert",
  duplicateClientNumber: "Doppelte Mandantennummer",
  duplicateClientNumberDetail:
    "Ein Mandant mit dieser Nummer existiert bereits",
  duplicateEmailAddress: "Doppelte E-Mail-Adresse",
  duplicateEmailAddressDetail:
    "Ein Mandant mit dieser E-Mail existiert bereits.",
  invalidResponse: "Ungültige Antwort",
  invalidResponseDetails: "Der Server hat eine ungültige Antwort zurückgegeben",
  clientNumberRequired: "Mandantennummer ist erforderlich",
  nameRequired: "Name ist erforderlich",
  emailRequired: "E-Mail ist erforderlich",
  emailInvalid: "Ungültige E-Mail-Adresse",
  phoneNumberRequired: "Telefonnummer ist erforderlich",
  vatIdIsTaken:
    "Ein Mandant mit dem gleichen Umsatzsteuer-Identifikationsnummer existiert bereits",
  selectSystemOfAccounts: "Bitte wählen Sie einen Kontenrahmen aus",
  invalidEmail: "Ungültige E-Mail-Adresse",
  vatIdRequired: "USt-IdNr. ist erforderlich",
};

const ENGLISH_TRANSLATIONS = {
  save: "Save",
  success: "Success",
  successDetail: "Accounting client saved successfully",
  duplicateClientNumber: "Duplicate client number",
  duplicateClientNumberDetail: "A client with this number already exists",
  duplicateEmailAddress: "Duplicate email address",
  duplicateEmailAddressDetail:
    "A client with this email address already exists",
  invalidResponse: "Invalid Response",
  invalidResponseDetails: "The server returned an invalid response. ",
  clientNumberRequired: "Client number is required",
  nameRequired: "Name is required",
  emailRequired: "Email is required",
  emailInvalid: "Invalid email address",
  phoneNumberRequired: "Phone number is required",
  vatIdIsTaken: "A client with the same VAT ID already exists",
  selectSystemOfAccounts: "Please select a system of accounts type",
  invalidEmail: "Invalid email address",
  vatIdRequired: "VAT ID is required",
};

export const AccountingClientFormSchema = (
  translations: typeof ENGLISH_TRANSLATIONS
) =>
  z.object({
    client_number: z.string().min(1, translations.clientNumberRequired),
    name: z.string().min(1, translations.nameRequired),
    system_of_accounts: z.string().uuid(translations.selectSystemOfAccounts),
    email: z.string().email(translations.invalidEmail).nullable(),
    vat_id: z.string().min(1, translations.vatIdRequired),
  });

export type AccountingClientFormValue = z.infer<
  ReturnType<typeof AccountingClientFormSchema>
>;

function BasicInformation(props: {
  formOptions: FormOptions;
  accountingClient: AccountingClient | null;
  setAccountingClient: (accountingClient: AccountingClient) => void;
}) {
  const toastRef = useRef<Toast | null>(null);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;
  const navigate = useNavigate();
  const successToastTriggered = useRef(false);

  const { createAccountingClient, updateAccountingClient } =
    useAccountingClientApi();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { refreshAccountingClients } =
    useContext(AccountingClientsContext) || throwError();

  useEffect(() => {
    if (!successToastTriggered.current) {
      successToastTriggered.current = true;

      const urlSearchParams = new URLSearchParams(window.location.search);

      if (urlSearchParams.has("saved")) {
        toastRef.current?.show({
          severity: "success",
          summary: translations.success,
          detail: translations.successDetail,
        });
      }
    }
  }, [toastRef, successToastTriggered, translations]);

  const defaultValues: AccountingClientFormValue = useMemo(() => {
    return (
      props.accountingClient ?? {
        client_number: "",
        name: "",
        system_of_accounts: "",
        email: null,
        vat_id: "",
      }
    );
  }, [props.accountingClient]);

  const form = useForm<AccountingClientFormValue>({
    resolver: zodResolver(AccountingClientFormSchema(translations)),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [form, defaultValues]);

  const onSubmit = async (formData: AccountingClientFormValue) => {
    setIsSubmitting(true);

    let response: MainApiFetchJSONResponse<
      | { status: 200; body: AccountingClient }
      | {
          status: 409;
          body: {
            detail:
              | "email-is-taken"
              | "client-number-is-taken"
              | "vat-id-is-taken";
          };
        }
    >;

    if (props.accountingClient) {
      response = await updateAccountingClient(
        formData,
        props.accountingClient.id
      );
    } else {
      response = await createAccountingClient(formData);
    }

    setIsSubmitting(false);

    if (response.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(response.error),
        detail: getCommunicationErrorMessage(response.error),
      });
      return;
    }

    if (response.response.status === 409) {
      let summary: string;
      let detail: string;

      if (response.response.body.detail === "client-number-is-taken") {
        summary = translations.duplicateClientNumber;
        detail = translations.duplicateClientNumberDetail;
      } else if (response.response.body.detail === "email-is-taken") {
        summary = translations.duplicateEmailAddress;
        detail = translations.duplicateEmailAddressDetail;
      } else if (response.response.body.detail === "vat-id-is-taken") {
        summary = translations.vatIdIsTaken;
        detail = "";
      } else {
        throw new Error();
      }

      toastRef.current?.show({
        severity: "warn",
        summary,
        detail,
      });
      return;
    }

    if (!props.accountingClient) {
      navigate(
        EDIT_CLIENTS_ROUTES.edit.getHref({
          accountingClientId: response.response.body.id,
          saved: true,
        })
      );
    } else {
      toastRef.current?.show({
        severity: "success",
        summary: translations.success,
        detail: translations.successDetail,
      });

      navigate(
        EDIT_CLIENTS_ROUTES.edit.getHref({
          accountingClientId: response.response.body.id,
        })
      );
    }

    refreshAccountingClients();
  };

  return (
    <div className={styles.page}>
      <Toast ref={toastRef} />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.formSection}>
          <ClientData
            accountingClient={props.accountingClient}
            system_of_accounts_types={
              props.formOptions.system_of_accounts_types
            }
            form={form}
          />
        </div>
        <div className={styles.formSection}>
          <ContactInformation
            form={form}
            accountingClient={props.accountingClient}
          />
        </div>

        <Button
          type="submit"
          className={styles.submit}
          label={translations.save}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </form>
    </div>
  );
}

export default BasicInformation;
