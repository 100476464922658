import { Navbar } from "./navbar/navbar";
import { Results } from "./results/results";
import { AccountingClientProvider } from "./accounting-clients/accounting-client-context";
import { QuickFiltersProvider } from "./navbar/quick-filters/quick-filters-context";
import { ResultsProvider } from "./results/result-context";
import { SortingProvider } from "./sorting/sorting-context";

export function Metrics() {
  return (
    <>
      <AccountingClientProvider>
        <QuickFiltersProvider>
          <SortingProvider>
            <ResultsProvider>
              <Navbar />
              <Results />
            </ResultsProvider>
          </SortingProvider>
        </QuickFiltersProvider>
      </AccountingClientProvider>
    </>
  );
}
