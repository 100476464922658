import styles from "./styles.module.scss";
import { Card } from "primereact/card";
import select_client from "./images/select_client.png";
import go_to_bank_bookings from "./images/go_to_bank_bookings.png";
import set_filters from "./images/set_filters.png";
import export_preview from "./images/export_preview.png";
import success_message from "./images/success_message.png";
import download_xml_template from "./images/download_xml_template.png";
import go_to_imports_section_a from "./images/go_to_imports_section_a.png";
import go_to_imports_section_b from "./images/go_to_imports_section_b.png";
import select_kanzlei21_template from "./images/select_kanzlei21_template.png";
import start_import from "./images/start_import.png";
import select_file_to_import from "./images/select_file_to_import.png";
import finish_import from "./images/finish_import.png";

const ExportBookingToEdreweGerman = () => {
  return (
    <Card className={styles.tutorialsCard}>
      <h1>Tutorial: Export von Buchungen aus Kanzlei21 und Import in Edrewe</h1>
      <p>
        In diesem Tutorial erfahren Sie, wie Sie Bankbuchungen aus der
        Kanzlei21-Webanwendung im CSV-Format exportieren und in Edrewe
        importieren können.
      </p>

      <section className={styles.content}>
        <h2>1. Schritte zum Export in Kanzlei21:</h2>
        <ul>
          <li>
            <strong>Anmeldung:</strong> Melden Sie sich in der
            Kanzlei21-Webanwendung an.
          </li>
          <li>
            <strong>Mandanten auswählen:</strong> Auf der linken Seite des
            Dashboards sehen Sie eine Sidebar. Wählen Sie hier einen Mandanten
            aus dem Dropdown-Menü aus.
          </li>
          <img src={select_client} className={styles.screenshot} />
          <li>
            <strong>Bankbuchungen aufrufen:</strong> Wurde ein Mandant
            ausgewählt, dann klicken Sie in der Sidebar auf Bankbuchungen.
            Standardmäßig werden alle Bankbuchungen des Standardkontos angezeigt
            (normalerweise das Konto 1200).
          </li>
          <img src={go_to_bank_bookings} className={styles.screenshot} />
          <li>
            <strong>Filter setzen:</strong> Falls nötig, können Sie oben im
            Top-Menü ein anderes Bankkonto auswählen, Datumsfilter setzen oder
            weitere Filter anwenden. Hinweis: Für den Import in Edrewe sollten
            die Buchungen immer kalenderjahresweise exportiert werden. (Year
            filter will be available.)
          </li>
          <img src={set_filters} className={styles.screenshot} />
          <li>
            <strong>Exportieren:</strong> Klicken Sie oben rechts im Top-Menü
            auf <em>CSV exportieren</em>. Ein Popup erscheint mit einer
            Zusammenfassung der zu exportierenden Buchungen. Wenn Anpassungen
            notwendig sind, klicken Sie auf den <em>Cancel</em>-Button, nehmen
            die Anpassungen vor und klicken erneut auf <em>CSV exportieren</em>.
            Sobald alles korrekt ist, klicken Sie auf{" "}
            <em>CSV jetzt exportieren</em>. Die CSV-Datei wird erstellt und auf
            Ihrem Computer heruntergeladen.
          </li>
          <img src={export_preview} className={styles.screenshot} />
          <li>
            <strong>Erfolgsmeldung:</strong> Eine Erfolgsmeldung wird angezeigt.
            Die CSV-Datei enthält die folgenden Spalten: Umsatz,
            Soll-Kennzeichen, Konto, Belegdatum, Gegenkonto, IBAN, Buchungstext,
            Buchungsschlüssel.
          </li>
          <img src={success_message} className={styles.screenshot} />
          <li>
            <strong>XML-Vorlage herunterladen:</strong> Im Erfolgsmeldung-Popup
            finden Sie einen Link zum Herunterladen der XML-Vorlage für den
            Import in Edrewe. (New message will guide for XML download.)
            <p>
              Falls Sie das Popup geschlossen haben, können Sie die Vorlage über
              einen anderen Link herunterladen.
            </p>
          </li>
          <img src={download_xml_template} className={styles.screenshot} />
        </ul>
      </section>

      <section>
        <h2>2. Schritte zum Import in Edrewe:</h2>
        <ul>
          <li>
            <strong>Anmeldung:</strong> Melden Sie sich in der Edrewe-Anwendung
            an.
          </li>
          <li>
            <strong>Import-Bereich auswählen:</strong> Stellen Sie sicher, dass
            Sie sich im Tab <em>Buchführung</em> der Sidebar befinden.
            <img src={go_to_imports_section_a} className={styles.screenshot} />
            <ul className={styles.subStep}>
              <li>
                Ist die Import-Option in der Sidebar aktiv, klicken Sie direkt
                darauf.
              </li>
              <li>
                Ist die Import-Option nicht aktiv, wählen Sie einen Mandanten
                auf der Hauptseite aus. Doppelklicken Sie dann auf ein Jahr (z.
                B. 2024). Nun sollte die Import-Option in der Sidebar aktiv
                sein.
              </li>
              <img
                src={go_to_imports_section_b}
                className={styles.screenshot}
              />
            </ul>
          </li>
          <li>
            <strong>Kanzlei21-XML-Vorlage auswählen:</strong> Wechseln Sie zum
            Tab <em>Buchungen & Sonstige Daten</em>. Klicken Sie im oberen
            Top-Menü auf <em>Austausch</em> und wählen Sie{" "}
            <em>Laden aus einer Datei</em>. Wählen Sie die heruntergeladene
            XML-Vorlage aus und klicken Sie auf <em>Öffnen</em>.
          </li>
          <img src={select_kanzlei21_template} className={styles.screenshot} />
          <li>
            <strong>Datenimport starten:</strong> In der Liste der
            Import-Vorlagen finden Sie nun die neue Vorlage <em>Kanzlei21</em>.
            Wählen Sie sie aus und klicken Sie im Top-Menü auf{" "}
            <em>Auswahl Importdaten</em>. Ein Popup{" "}
            <em>Import aus Fremddaten</em> erscheint.
          </li>
          <img src={start_import} className={styles.screenshot} />
          <li>
            <strong>Datei auswählen:</strong> Klicken Sie auf{" "}
            <em>Durchsuchen</em>, um den Ordner auszuwählen, in dem Sie die
            CSV-Datei von Kanzlei21 gespeichert haben (wahrscheinlich der
            Downloads-Ordner). Der Dateiname hat das Format <em>Buchungen</em>.
            Wählen Sie die Datei aus und klicken Sie auf <em>Weiter</em>.
          </li>
          <img src={select_file_to_import} className={styles.screenshot} />
          <li>
            <strong>Einstellungen prüfen:</strong> Überprüfen Sie die Vorschau
            und klicken Sie auf <em>Weiter</em>. Falls Änderungen nötig sind,
            nehmen Sie diese vor.
          </li>
          <li>
            <strong>Import abschließen:</strong> Im letzten Schritt klicken Sie
            auf <em>Fertigstellen</em>. Eine Erfolgsmeldung wird angezeigt.
          </li>

          <img src={finish_import} className={styles.screenshot} />
          <li>
            <strong>Fertig!</strong> Sie haben Ihre Buchungen erfolgreich aus
            Kanzlei21 in Edrewe importiert. Sie finden diese unter dem
            entsprechenden Mandanten und Jahr im <em>Buchungsprotokoll</em>.
          </li>
        </ul>
      </section>
    </Card>
  );
};

export default ExportBookingToEdreweGerman;
