import { Divider } from "primereact/divider";
import { Tag } from "primereact/tag";
import { Detail } from "./detail/detail";
import styles from "./details.module.scss";
import { Document } from "../../results/results-context";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { formatFileSize } from "../../../../../file-size";

const dateFormatter = new Intl.DateTimeFormat(undefined, {
  day: "numeric",
  month: "short",
  year: "numeric",
});

const GERMAN_TRANSLATIONS = {
  documentName: "Dokumentname",
  uploadedDate: "Hochladedatum",
  type: "Typ",
  size: "Größe",
  mobileUpload: "Mobil hochladen",
  numberOfPages: "Anzahl der Seiten",
};

const ENGLISH_TRANSLATIONS = {
  documentName: "Document Name",
  uploadedDate: "Uploaded Date",
  type: "Type",
  size: "Size",
  mobileUpload: "Mobile Upload",
  numberOfPages: "No. of pages",
};

export function Details(props: { selectedDocument: Document }) {
  const currentLanguage = useCurrentLanguage();

  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.rows}>
      <div className={styles.row}>
        <div className={styles.column}>
          <Detail label={translations.documentName}>
            <p className={styles.bigText}>{props.selectedDocument.name}</p>
          </Detail>
        </div>
        <Divider layout="vertical" />
        <div className={styles.column}>
          <Detail label={translations.uploadedDate}>
            <p className={styles.bigText}>
              {dateFormatter.format(props.selectedDocument.createdAt)}
            </p>
          </Detail>
        </div>
      </div>

      {(() => {
        if (props.selectedDocument.document_upload.type === "desktop") {
          return (
            <div className={styles.row}>
              <div className={styles.column}>
                <Detail label={translations.type}>
                  <Tag
                    value={props.selectedDocument.document_upload.file_type}
                    severity="info"
                    className={`px-3 py-2 ${styles.tag}`}
                  />
                </Detail>
              </div>
              <Divider layout="vertical" />
              <div className={styles.column}>
                <Detail label={translations.size}>
                  <p>
                    {formatFileSize(
                      props.selectedDocument.document_upload.file_size
                    )}
                  </p>
                </Detail>
              </div>
            </div>
          );
        } else if (props.selectedDocument.document_upload.type === "mobile") {
          return (
            <div className={styles.row}>
              <div className={styles.column}>
                <Detail label={translations.type}>
                  <Tag value={translations.mobileUpload} severity="info" className={`px-3 py-2 ${styles.tag}`} />
                </Detail>
              </div>
              <Divider layout="vertical" />
              <div className={styles.column}>
                <Detail label={translations.numberOfPages}>
                  <p>
                    {props.selectedDocument.document_upload.pages.length}
                  </p>
                </Detail>
              </div>
            </div>
          );
        } else {
          throw new Error();
        }
      })()}
    </div>
  );
}
