import styles from "./page.module.scss";
import { Card } from "primereact/card";
import { ResetPassword } from "./reset-password";
import { useCurrentLanguage } from "../../language/current-language";
import { SupportedLanguage } from "../../language/supported-languages";
import { FinApi } from "./components/finapi/finapi";
const GERMAN_TRANSLATIONS = {
  resetYourPassword: "Passwort zurücksetzen",
  bankAccountsPlaid: "Bankkonten Plaid",
  bankAccountsFinApi: "Bankkonten FinApi",
};

const ENGLISH_TRANSLATIONS = {
  resetYourPassword: "Reset your password",
  bankAccountsPlaid: "Bank accounts from Plaid",
  bankAccountsFinApi: "Bank accounts from FinApi",
};

function _ClientHomePage() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <main className={styles.main}>
      <div className={styles.marginBottom}>
        <ResetPassword />
      </div>
      {/* <Card title={translations.bankAccountsPlaid} className={styles.marginBottom}>
        <div>
          <Plaid />
        </div>
      </Card> */}
      <Card
        title={translations.bankAccountsFinApi}
        className={styles.marginBottom}
      >
        <div>
          <FinApi />
        </div>
      </Card>
    </main>
  );
}

export function ClientHomePage() {
  return <_ClientHomePage />;
}
