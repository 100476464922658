import { useContext, useMemo } from "react";
import { z } from "zod";
import { CommunicationError } from "./communication-errors/communication-errors";
import { useLogger } from "./logger";
import { AccountantAuthenticationStateContext } from "./accountants/authentication/authentication-state";
import { throwError } from "./throw-error";
import { ClientAuthenticationStateContext } from "./accounting-clients/authentication/authentication-state";

export const MAIN_API_URL = import.meta.env.VITE_MAIN_API_URL || throwError();

export type MainApiFetchJSONResponse<T> =
  | {
      error: CommunicationError;
    }
  | {
      error?: undefined;
      response: T;
    };

export type MainApiFetchResponse =
  | {
      error: CommunicationError;
    }
  | {
      error?: undefined;
      response: Response;
    };

interface MainApi {
  fetchJSON<
    ZodSchema extends z.ZodType<{
      status: number;
      body?: unknown;
    }>,
  >(args: {
    schema: ZodSchema;
    path: string;
    body?: unknown;
    method: "HEAD" | "GET" | "DELETE" | "POST" | "PATCH" | "PUT";
  }): Promise<MainApiFetchJSONResponse<z.TypeOf<ZodSchema>>>;
  fetch(
    path: string,
    acceptableStatuses: number[],
    init?: RequestInit | undefined
  ): Promise<MainApiFetchResponse>;
}

export function useMainApi(): MainApi {
  const logger = useLogger();

  const { setSessionState: setAccountantAuthenticationSessionState } =
    useContext(AccountantAuthenticationStateContext) || throwError();
  const { setSessionState: setClientAuthenticationSessionState } =
    useContext(ClientAuthenticationStateContext) || throwError();

  return useMemo(() => {
    const logOut = async (authType: "accountant_user" | "mobile_app_user") => {
      if (authType === "accountant_user") {
        setAccountantAuthenticationSessionState({
          loading: true,
        });
      } else if (authType === "mobile_app_user") {
        setClientAuthenticationSessionState({
          loading: true,
        });
      } else {
        throw new Error();
      }

      let response: Response;

      const path = (() => {
        if (authType === "accountant_user") {
          return "/accountant_users/logout";
        } else if (authType === "mobile_app_user") {
          return "/mobile_app_users/logout";
        } else {
          throw new Error("Invalid auth type");
        }
      })();

      try {
        response = await fetch(`${MAIN_API_URL}${path}`, {
          method: "POST",
          credentials: "include",
        });
      } catch (err) {
        if (authType === "accountant_user") {
          setAccountantAuthenticationSessionState({
            error: CommunicationError.ConnectionFailure,
          });
        } else if (authType === "mobile_app_user") {
          setClientAuthenticationSessionState({
            error: CommunicationError.ConnectionFailure,
          });
        } else {
          throw new Error();
        }

        return;
      }

      if (response.status === 200) {
        if (authType === "accountant_user") {
          setAccountantAuthenticationSessionState({
            data: null,
          });
        } else if (authType === "mobile_app_user") {
          setClientAuthenticationSessionState({
            data: null,
          });
        } else {
          throw new Error();
        }
      } else {
        const text = await response.text();

        logger.logError(new Error(), {
          authType,
          response: {
            status: response.status,
            text,
          },
        });

        if (authType === "accountant_user") {
          setAccountantAuthenticationSessionState({
            error: CommunicationError.UnexpectedResponse,
          });
        } else if (authType === "mobile_app_user") {
          setClientAuthenticationSessionState({
            error: CommunicationError.UnexpectedResponse,
          });
        } else {
          throw new Error();
        }
      }
    };

    const logoutAccountant = () => logOut("accountant_user");
    const logoutClient = () => logOut("mobile_app_user");

    return {
      logoutAccountant,
      logoutClient,
      fetchJSON: async (args) => {
        let response: Response;

        try {
          response = await fetch(`${MAIN_API_URL}${args.path}`, {
            method: args.method,
            body: ["POST", "PATCH", "PUT"].includes(args.method)
              ? JSON.stringify(args.body)
              : undefined,
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          });
        } catch (error) {
          return {
            error: CommunicationError.ConnectionFailure,
          };
        }

        const responseJSON = await response.json();

        const validationResult = args.schema.safeParse({
          status: response.status,
          body: responseJSON,
        });

        if (validationResult.success) {
          return {
            response: validationResult.data,
          };
        } else {
          if (
            response.status === 401 &&
            response.headers.get("X-Authentication-Type") === "accountant_user"
          ) {
            logoutAccountant();

            return {
              error: CommunicationError.AbortedAndDealtWith,
            };
          } else if (
            response.status === 401 &&
            response.headers.get("X-Authentication-Type") === "mobile_app_user"
          ) {
            logoutClient();

            return {
              error: CommunicationError.AbortedAndDealtWith,
            };
          } else {
            logger.logError(new Error(), {
              request: {
                path: args.path,
                method: args.method,
              },
              response: {
                status: response.status,
                validationErrors: validationResult.error.format(),
              },
            });

            return {
              error: CommunicationError.UnexpectedResponse,
            };
          }
        }
      },
      fetch: async (
        path,
        acceptableStatuses,
        init
      ): Promise<MainApiFetchResponse> => {
        let response: Response;

        try {
          response = await fetch(`${MAIN_API_URL}${path}`, {
            ...init,
            credentials: "include",
          });
        } catch (error) {
          return {
            error: CommunicationError.ConnectionFailure,
          };
        }

        if (acceptableStatuses.includes(response.status)) {
          return {
            response,
          };
        }

        if (
          response.status === 401 &&
          response.headers.get("X-Authentication-Type") === "accountant_user"
        ) {
          logoutAccountant();

          return {
            error: CommunicationError.AbortedAndDealtWith,
          };
        } else if (
          response.status === 401 &&
          response.headers.get("X-Authentication-Type") === "mobile_app_user"
        ) {
          logoutClient();

          return {
            error: CommunicationError.AbortedAndDealtWith,
          };
        } else {
          logger.logError(new Error(), {
            request: {
              path: path,
              method: init?.method,
            },
            response: {
              status: response.status,
            },
          });

          return {
            error: CommunicationError.UnexpectedResponse,
          };
        }
      },
    };
  }, [
    setAccountantAuthenticationSessionState,
    setClientAuthenticationSessionState,
    logger,
  ]);
}
