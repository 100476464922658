import { throwError } from "../../../../../throw-error";
import { useContext, useRef, useState } from "react";
import { BankTransactionsPageBankAccountsContext } from "./bank-accounts/bank-accounts-context";
import { useAccountingClientId } from "../accounting-client-id";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { useMainApi } from "../../../../../main-api";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../communication-errors/communication-error-messages";
import { CommunicationError } from "../../../../../communication-errors/communication-errors";
import { BankTransactionsPageResultsContext } from "./results/results-context";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  uploadButtonLabel: "MT940 hochladen",
  tooltipMessage:
    "Für dieses Konto können keine MT940-Dateien hochgeladen werden, da der Mandant bereits Plaid als Quelle für Banktransaktionen eingerichtet hat.",
  successMessage: "Erfolg",
  successDetail: "MT940-Datei erfolgreich hochgeladen und verarbeitet",
};

const ENGLISH_TRANSLATIONS = {
  uploadButtonLabel: "Upload MT940",
  tooltipMessage:
    "You cannot upload MT940 files for this account, since the client already set up Plaid as the source of bank transactions.",
  successMessage: "Success",
  successDetail: "MT940 file uploaded and processed successfully",
};

export function UploadMt940Button(props: { className?: string }) {
  const mainApi = useMainApi();
  const accountingClientId = useAccountingClientId();
  const { selectedBankAccount } =
    useContext(BankTransactionsPageBankAccountsContext) || throwError();
  const { setForcedUpdateKey } =
    useContext(BankTransactionsPageResultsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [isUploading, setIsUploading] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const toastRef = useRef<Toast | null>(null);

  return (
    <>
      <Toast ref={toastRef} />
      <input
        type="file"
        onChange={(e) => {
          (async () => {
            const file = e.target.files?.[0] ?? throwError();

            setIsUploading(true);

            const urlSearchParams = new URLSearchParams();

            urlSearchParams.append("accounting_client_id", accountingClientId);

            if (selectedBankAccount) {
              urlSearchParams.append("bank_account_id", selectedBankAccount.id);
            }

            const formData = new FormData();
            formData.append("file", file);

            const res = await mainApi.fetch(
              `/mt940/upload?${urlSearchParams.toString()}`,
              [200],
              {
                method: "POST",
                body: formData,
              }
            );

            inputRef.current!.value = "";

            setIsUploading(false);

            if (res.error) {
              toastRef.current!.show({
                severity: "error",
                summary: getCommunicationErrorTitle(res.error),
                detail: getCommunicationErrorMessage(res.error),
              });

              return;
            }

            if (!res.response.ok) {
              toastRef.current!.show({
                severity: "error",
                summary: getCommunicationErrorTitle(
                  CommunicationError.UnexpectedResponse
                ),
                detail: getCommunicationErrorMessage(
                  CommunicationError.UnexpectedResponse
                ),
              });

              return;
            }

            toastRef.current!.show({
              severity: "success",
              summary: translations.successMessage,
              detail: translations.successDetail,
            });

            setForcedUpdateKey?.((prevKey: number) => prevKey + 1);
          })();
        }}
        style={{ display: "none" }}
        ref={inputRef}
      />
      {selectedBankAccount && selectedBankAccount.linked_to_plaid && (
        <Tooltip target=".linked-to-plaid-message" position={"bottom"} />
      )}

      <div
        className="linked-to-plaid-message"
        data-pr-tooltip={translations.tooltipMessage}
      >
        <Button
          severity={"secondary"}
          icon={"pi pi-file-import"}
          className={props.className}
          disabled={
            isUploading ||
            !selectedBankAccount ||
            selectedBankAccount.linked_to_plaid
          }
          loading={isUploading}
          label={translations.uploadButtonLabel}
          onClick={() => {
            inputRef.current!.click();
          }}
        />
      </div>
    </>
  );
}
