import { useCurrentLanguage } from '../../../../language/current-language';
import { SupportedLanguage } from '../../../../language/supported-languages';
import ExportBookingToEdreweGerman from './export-bookings-to-edrewe-german';
import ExportBookingToEdreweEnglish from './export-bookings-to-edrewe-english';

function ExportBookingToEdrewe() {
  const currentLanguage = useCurrentLanguage();

  if (currentLanguage === SupportedLanguage.German) {
    return <ExportBookingToEdreweGerman />
  }

  return <ExportBookingToEdreweEnglish />
}

export default ExportBookingToEdrewe
