import { Button } from "primereact/button";
import styles from "./actions.module.scss";
import { useContext } from "react";
import { ResultsContext } from "../../results/results-context";
import { throwError } from "../../../../../throw-error";
import { SupportedLanguage } from "../../../../../language/supported-languages";
import { useCurrentLanguage } from "../../../../../language/current-language";


const GERMAN_TRANSLATIONS = {
    cancel: "Abbrechen",
};

const ENGLISH_TRANSLATIONS = {
    cancel: "Cancel",
};

export function Actions() {
    const { setSelectedDocumentId } = useContext(ResultsContext) || throwError();
    const currentLanguage = useCurrentLanguage();

    const translations =
        currentLanguage === SupportedLanguage.German
            ? GERMAN_TRANSLATIONS
            : ENGLISH_TRANSLATIONS;

    return (
        <div className={styles.actionBar}>
            <Button
                icon="pi pi-times"
                severity="danger"
                label={translations.cancel}
                onClick={() => setSelectedDocumentId(undefined)}
            />
        </div>
    );
}
