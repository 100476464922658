import React, { useState, useRef, useEffect, ReactNode } from "react";
import { throwError } from "../throw-error";

interface Size {
  width: number;
  height: number;
}

interface MeasureSizeProps {
  children: (size: Size) => ReactNode;
}

const MeasureSize: React.FC<MeasureSizeProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0] || throwError();

      const { width, height } = entry.contentRect;

      setSize({ width, height });
    });

    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, []);

  return (
    <div ref={containerRef} style={{ width: "100%", height: "100%" }}>
      {children(size)}
    </div>
  );
};

export default MeasureSize;
