import { useContext } from "react";
import { Actions } from "./actions/actions";
import { EditDocumentData } from "./edit-document-data/edit-document-data";
import styles from "./document-data-screen.module.scss";
import { Panel } from "primereact/panel";
import { ResultsContext, Document } from "../results/results-context";
import { throwError } from "../../../../../../throw-error";
import { Divider } from "primereact/divider";
import { DocumentPreview } from "./document-preview/document-preview";

function _DocumentDataScreen({
  selectedDocument,
}: {
  selectedDocument: Document;
}) {
  return (
    <>
      <Panel headerTemplate={Actions} className={styles.documentDetailsPanel}>
        <div className={styles.layout}>
          <div className={`${styles.documentData} ${styles.scrollableContent}`}>
            <EditDocumentData selectedDocument={selectedDocument} />
          </div>
          <Divider layout="vertical" />
          <div
            className={`${styles.documentPreview} ${styles.scrollableContent}`}
          >
            <DocumentPreview selectedDocument={selectedDocument} />
          </div>
        </div>
      </Panel>
    </>
  );
}

export function DocumentDataScreen() {
  const { selectedDocument } = useContext(ResultsContext) || throwError();

  if (!selectedDocument) {
    return;
  }

  return <_DocumentDataScreen selectedDocument={selectedDocument} />;
}
