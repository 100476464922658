import { Button } from "primereact/button";
import styles from "./actions.module.scss";
import { useContext } from "react";
import { throwError } from "../../../../../../../throw-error";
import { ResultsContext } from "../../results/results-context";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  documentDetails: "Dokumentdetails",
  cancel: "Abbrechen",
};

const ENGLISH_TRANSLATIONS = {
  documentDetails: "Document Details",
  cancel: "Cancel",
};

export function Actions() {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const { setSelectedDocumentId } = useContext(ResultsContext) || throwError();

  return (
    <div className={styles.actionBar}>
      <b className={`${styles.fontSize1_25Rem} ${styles.marginLeftSpacer}`}>
        {translations.documentDetails}
      </b>
      <Button
        icon="pi pi-times"
        label={translations.cancel}
        severity="danger"
        onClick={() => setSelectedDocumentId(undefined)}
      />
    </div>
  );
}
