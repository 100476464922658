import styles from "./details-panel.module.scss";
import { Panel } from "primereact/panel";
import { useContext } from "react";
import { ResultsContext } from "../results/results-context";
import { Divider } from "primereact/divider";
import { throwError } from "../../../../throw-error";
import { Actions } from "./actions/actions";
import { Details } from "./details/details";
import { Issues } from "./issues/issues";
import { DocumentPreview } from "./document-preview/document-preview";

export function DetailsPanel() {
  const { selectedDocument } = useContext(ResultsContext) || throwError();

  if (!selectedDocument) {
    return;
  }

  return (
    <>
      <Panel headerTemplate={Actions}>
        <div className={styles.row}>
          <div className={styles.flex_2}>
            <Details selectedDocument={selectedDocument} />
          </div>
          <Divider layout="vertical" />
          <div className={styles.flex_1}>
            <Issues selectedDocument={selectedDocument} />
          </div>
          <Divider layout="vertical" />
          <div className={styles.flex_1}>
            <DocumentPreview document={selectedDocument} />
          </div>
        </div>
      </Panel>
    </>
  );
}
