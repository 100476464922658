import { useMemo, useContext, useCallback, useRef } from "react";
import { Column } from "react-data-grid";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import styles from "./bank-accounts.module.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { getCommunicationErrorMessage } from "../../../../../../../../communication-errors/communication-error-messages";
import { AccountingClient } from "../../../accountingClientLoader";
import { DEFAULT_SORT_COLUMN } from "./results/sorting/sorting-constants";
import { throwError } from "../../../../../../../../throw-error";
import { BankAccountsToolbar } from "./toolbar/bank-accounts-toolbar";
import {
  SortColumn,
  BankAccountsSortingContext,
  BankAccountsSortingContextProvider,
} from "./results/sorting/sorting-context";
import {
  BankAccount,
  BankAccountsResultsContext,
  BankAccountsResultsContextProvider,
} from "./results/results-context";
import { Menu } from "primereact/menu";
import { ContextMenu } from "./results/context-menu";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { BankAccountsFiltersContextProvider } from "./toolbar/filters-context";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { ReactDataGrid } from "../../../../../../../../react-data-grid";

const GERMAN_TRANSLATIONS = {
  accountNumber: "Kontonummer",
  accountName: "Kontoname",
  iban: "IBAN",
  bookings: "Buchungen",
  loadingMoreRows: "Weitere Zeilen werden geladen...",
};

const ENGLISH_TRANSLATIONS = {
  accountNumber: "Account Number",
  accountName: "Account Name",
  iban: "IBAN",
  bookings: "Bookings",
  loadingMoreRows: "Loading more rows...",
};

function _BankAccounts({
  selectedAccountId,
  setSelectedAccountId,
  accountingClient,
}: {
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
  accountingClient: AccountingClient;
}) {
  const { results, setOffset } =
    useContext(BankAccountsResultsContext) || throwError();

  const selectedAccount = results.data?.rows.find(
    (account) => account.account_id === selectedAccountId
  );

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const sorting = useContext(BankAccountsSortingContext) || throwError();

  const menuRef = useRef<Menu>(null);

  const toastRef = useRef<Toast>(null);

  const getRowKey = useCallback(
    (r: Partial<BankAccount>) => r.id || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn((sortColumn ?? DEFAULT_SORT_COLUMN) as SortColumn);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return [sorting.sortColumn];
  }, [sorting]);

  const columns: Column<BankAccount>[] = useMemo(() => {
    return [
      {
        key: "account_number",
        name: `${translations.accountNumber}`,
        editable: true,
        sortable: true,
      },
      {
        key: "account_name",
        name: `${translations.accountName}`,
        editable: true,
        sortable: true,
      },
      { key: "iban", name: "IBAN", editable: true, sortable: true },
      {
        key: "total_transactions",
        name: `${translations.bookings}`,
        sortable: true,
      },
    ];
  }, [translations]);

  return (
    <div className={styles.page}>
      <Toast ref={toastRef} />
      <ConfirmDialog />
      <BankAccountsToolbar />

      {(() => {
        const data = results.data;

        if (results.loading && !results.data) {
          return (
            <div className={styles.loadingContainer}>
              <ProgressSpinner />
            </div>
          );
        }

        if (results.error) {
          return (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>
                {getCommunicationErrorMessage(results.error)}
              </p>
            </div>
          );
        }

        if (data && data.rows.length > 0) {
          return (
            <>
              <ReactDataGrid
                columns={columns}
                rows={results.data.rows}
                sortColumns={sortColumns}
                rowKeyGetter={getRowKey}
                onSortColumnsChange={onSortColumnsChange}
                onWheelAndBottom={() => {
                  setOffset(data.rows.length);
                }}
                className={`${styles.table}`}
                onCellClick={(args, event) => {
                  setSelectedAccountId(args.row.account_id);
                  menuRef.current?.toggle(event);
                }}
              />
              -
              <ContextMenu
                selectedAccount={selectedAccount}
                accountingClientId={accountingClient.id}
                menuRef={menuRef}
                toastRef={toastRef}
              />
              {results.loading && (
                <div className={styles.loadingNextPageContainer}>
                  {translations.loadingMoreRows}
                </div>
              )}
            </>
          );
        }
      })()}
    </div>
  );
}

export default function BankAccounts({
  accountingClient,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  return (
    <BankAccountsFiltersContextProvider accountingClient={accountingClient}>
      <BankAccountsSortingContextProvider>
        <BankAccountsResultsContextProvider accountingClient={accountingClient}>
          <_BankAccounts
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
            accountingClient={accountingClient}
          />
        </BankAccountsResultsContextProvider>
      </BankAccountsSortingContextProvider>
    </BankAccountsFiltersContextProvider>
  );
}
