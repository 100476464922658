import { useNavigate } from "react-router-dom";
import { Menu as PrimeReactMenu } from "primereact/menu";
import styles from "./menu.module.scss";
import { DOCUMENTS_ROUTE } from "../documents/documents-route";

export function AccountingClientMenu() {
  const navigate = useNavigate();

  return (
    <PrimeReactMenu
      className={styles.primeReactMenu}
      model={[
        {
          label: "Documents",
          className: `${window.location.pathname.startsWith("/client/documents") ? "p-menuitem-active" : ""}`,
          command: () => {
            navigate(DOCUMENTS_ROUTE.getHref());
          },
        },
      ]}
    />
  );
}
