import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";
import styles from "./graph.module.scss";
import { JournalCount } from "../../result-context";
import { useMemo } from "react";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";
import { useCurrentLanguage } from "../../../../../../../language/current-language";

type Graph = {
  name: string;
  total: number;
  fill?: string;
};

const GERMAN_TRANSLATIONS = {
  fullyAutomated: "Vollautomatisiert",
  actionNeeded: "Handlungsbedarf",
  correctedByAccountant: "Manuell korrigiert",
  historical: "Historisch",
};

const ENGLISH_TRANSLATIONS = {
  fullyAutomated: "Fully Automated",
  actionNeeded: "Action Needed",
  correctedByAccountant: "Corrected by Accountant",
  historical: "Historical",
};

export function Graph({ journalCounts }: { journalCounts: JournalCount }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const graph_data: Graph[] = useMemo(() => {
    if (!journalCounts) {
      return [];
    }
    return [
      {
        name: translations.fullyAutomated,
        total: journalCounts.predicted_reduced_journals_count,
        fill: styles.successColor,
      },
      {
        name: translations.actionNeeded,
        total: journalCounts.action_needed_reduced_journals_count,
        fill: styles.errorColor,
      },
      {
        name: translations.correctedByAccountant,
        total: journalCounts.corrected_reduced_journals_count,
        fill: styles.infoColor,
      },
      {
        name: translations.historical,
        total: journalCounts.historical_reduced_journals_count,
        fill: styles.secondaryColor,
      },
    ];
  }, [journalCounts, translations]);

  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart className={styles.graph} data={graph_data}>
        <CartesianGrid strokeDasharray="1 6" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="total" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
}
