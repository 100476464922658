import { Toast } from "primereact/toast";
import {
  RefObject,
  useContext,
  useState,
  useEffect,
  useMemo,
  createContext,
  ReactNode,
  useRef,
} from "react";
import { z } from "zod";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../communication-errors/communication-error-messages";
import { CommunicationError } from "../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../main-api";
import { throwError } from "../../../../throw-error";
import {
  FiltersType,
  FiltersContext,
} from "../top-section/navbar/filters/filters-context";
import {
  QuickFilters,
  QuickFiltersContext,
} from "../top-section/navbar/quick-filters/quick-filters-context";
import { toDateString } from "../../../../dates";
import { SortColumn, SortingContext } from "../sorting/sorting-context";

const IssueSchema = z.object({
  id: z.string(),
  type: z.enum(["review_needed", "invalid_ibans"]),
});

export const DocumentSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.coerce.date(),
  issues: z.array(IssueSchema).nullable(),
  document_upload: z.union([
    z.object({
      type: z.literal("mobile"),
      pages: z.array(
        z.object({
          id: z.string(),
        })
      ),
    }),
    z.object({
      type: z.literal("desktop"),
      file_size: z.number(),
      file_type: z.string(),
    }),
  ]),
});

export type Document = z.TypeOf<typeof DocumentSchema>;

type RequestedParams = {
  offset: number;
  sortColumn: SortColumn | undefined;
  filters: FiltersType;
  quickFilters: QuickFilters;
};

function useProvider({ toastRef }: { toastRef: RefObject<Toast> }) {
  const mainApi = useMainApi();
  const { sortColumn } = useContext(SortingContext) || throwError();

  const { filters } = useContext(FiltersContext) || throwError();
  const { quickFilters } = useContext(QuickFiltersContext) || throwError();

  const [offset, setOffset] = useState(0);

  const [results, setResults] = useState<
    Readonly<{
      data?: {
        rows: Document[];
      };
      loading?: boolean;
      error?: CommunicationError;
    }>
  >({});

  const [lastRequestParams, setLastRequestParams] = useState<
    RequestedParams | undefined
  >();

  const [selectedDocumentId, setSelectedDocumentId] = useState<
    string | undefined
  >();

  useEffect(() => {
    (async () => {
      if (results.loading) {
        return;
      }

      const requestParams: RequestedParams = {
        offset,
        sortColumn,
        filters,
        quickFilters,
      };

      if (JSON.stringify(requestParams) === JSON.stringify(lastRequestParams)) {
        return;
      }

      setLastRequestParams(requestParams);

      const resetResults =
        JSON.stringify({
          sortColumn: requestParams.sortColumn,
          filters: requestParams.filters,
          quickFilters: requestParams.quickFilters,
        }) !==
        JSON.stringify({
          sortColumn: lastRequestParams?.sortColumn,
          filters: lastRequestParams?.filters,
          quickFilters: lastRequestParams?.quickFilters,
        });

      if (resetResults) {
        setResults(() => {
          return {};
        });
      }

      const _offset = resetResults ? 0 : offset;

      setResults((results) => {
        return {
          ...results,
          loading: true,
          error: undefined,
        };
      });

      const urlSearchParams = new URLSearchParams();

      urlSearchParams.append("sort_by", sortColumn?.columnKey ?? "created_at");
      urlSearchParams.append("sort_order", sortColumn?.direction ?? "DESC");
      urlSearchParams.append("offset", _offset.toString());

      for (const [key, value] of Object.entries({
        ...filters,
        ...quickFilters,
      })) {
        if (typeof value === "string" && value.trim().length > 0) {
          urlSearchParams.append(key, value);
        } else if (typeof value === "boolean") {
          urlSearchParams.append(key, value ? "true" : "false");
        } else if (value instanceof Date) {
          urlSearchParams.append(key, toDateString(value));
        }
      }

      const response = await mainApi.fetchJSON({
        method: "GET",
        path: `/documents/accounting-client/list?${urlSearchParams.toString()}`,
        schema: z.object({
          status: z.literal(200),
          body: z.array(DocumentSchema),
        }),
      });

      if (response.error) {
        setResults((results) => {
          return {
            ...results,
            loading: false,
            error: response.error,
          };
        });

        toastRef.current?.show({
          severity: "error",
          summary: getCommunicationErrorTitle(response.error),
          detail: getCommunicationErrorMessage(response.error),
        });

        return;
      }

      setResults((results) => {
        return {
          ...results,
          loading: false,
          data: {
            rows: resetResults
              ? response.response.body
              : [...(results.data?.rows || []), ...response.response.body],
          },
        };
      });
    })();
  }, [
    mainApi,
    offset,
    toastRef,
    results,
    lastRequestParams,
    filters,
    quickFilters,
    sortColumn,
  ]);

  const selectedDocument = results.data?.rows.find(
    (document) => document.id === selectedDocumentId
  );

  return useMemo(() => {
    return {
      offset,
      setOffset,
      selectedDocumentId,
      setSelectedDocumentId,
      results,
      setResults,
      selectedDocument,
    };
  }, [offset, selectedDocumentId, results, selectedDocument]);
}

export const ResultsContext = createContext<
  ReturnType<typeof useProvider> | undefined
>(undefined);

export function ResultsContextProvider(props: { children: ReactNode }) {
  const toastRef = useRef<Toast>(null);
  const value = useProvider({ toastRef });

  return (
    <ResultsContext.Provider value={value}>
      <Toast ref={toastRef} />
      {props.children}
    </ResultsContext.Provider>
  );
}
