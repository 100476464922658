export const DesignToken = {
  bodyColor: "hsl(0 0% 96%)",
  bodyElevated: "hsl(0 0% 100%)",
  primaryColor: "hsl(239 84% 67%)",
  secondaryColor: "hsl(215 16% 47%)",
  infoColor: "hsl(216 98% 52%)",
  successColor: "hsl(147 66% 47%)",
  warningColor: "hsl(30 100% 63%)",
  errorColor: "hsl(0 72% 59%)",

  /*
    Foreground colors
  */

  textColor: "hsl(215 14% 34%)",
  textMutedColor: "hsl(215 14% 34% / 0.5)",
  textPrimaryColor: "hsl(239 84% 67%)",
  textSecondaryColor: "hsl(215 16% 47%)",
  textInfoColor: "hsl(216 98% 52%)",
  textSuccessColor: "hsl(147 66% 40%)",
  textWarningColor: "hsl(30 100% 63%)",
  textErrorColor: "hsl(0 72% 59%)",
  borderColor: "hsl(215 14% 34% / 0.3)",
  borderWeakColor: "hsl(215 14% 34% / 0.1)",

  primaryTextColor: "hsl(0 0% 100%)",
  secondaryTextColor: "hsl(0 0% 100%)",
  infoTextColor: "hsl(0 0% 100%)",
  successTextColor: "hsl(0 0% 100%)",
  warningTextColor: "hsl(0 0% 100%)",
  errorTextColor: "hsl(0 0% 100%)",

  /* --- */

  disabledOpacity: 0.5,

  spacer: "1rem",

  fontFamily: "sans-serif",

  borderRadius: "6px",
  borderWidth: "1px",
  borderWidthLG: "3px",

  /* --- */

  highlightBg: "hsl(239 84% 92%)",
  highlightTextColor: "hsl(239 84% 50%)",

  infoHighlightBg: "hsl(216 98% 95%)",
  infoHighlightTextColor: "hsl(216 98% 50%)",

  successHighlightBg: "hsl(147 66% 92%)",
  successHighlightTextColor: "hsl(147 66% 50%)",

  warningHighlightBg: "hsl(30 100% 92%)",
  warningHighlightTextColor: "hsl(30 100% 50%)",

  errorHighlightBg: "hsl(0 72% 92%)",
  errorHighlightTextColor: "hsl(0 72% 50%)",

  /* --- */

  highlightTransparentBg: "hsl(239 84% 67% / 0.14)",
  infoHighlightTransparentBg: "hsl(216 98% 52% / 0.14)",
  successHighlightTransparentBg: "hsl(147 66% 47% / 0.14)",
  warningHighlightTransparentBg: "hsl(30 100% 63% / 0.14)",
  errorHighlightTransparentBg: "hsl(0 72% 59% / 0.14)",
};
