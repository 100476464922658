import { useMainApi } from "../../../../../../main-api";
import { z } from "zod";
import { AccountingClientSchema } from "../accountingClientLoader";
import { AccountingClientFormValue } from "./basic-information";

export const useAccountingClientApi = () => {
  const mainApi = useMainApi();

  const createAccountingClient = async (
    clientData: AccountingClientFormValue
  ) => {
    return mainApi.fetchJSON({
      path: "/accounting_clients/create",
      method: "POST",
      body: {
        ...clientData,
      },
      schema: z.union([
        z.object({
          status: z.literal(409),
          body: z.object({
            detail: z.union([
              z.literal("client-number-is-taken"),
              z.literal("email-is-taken"),
              z.literal("vat-id-is-taken"),
            ]),
          }),
        }),
        z.object({
          status: z.literal(200),
          body: AccountingClientSchema,
        }),
      ]),
    });
  };

  const updateAccountingClient = async (
    clientData: AccountingClientFormValue,
    clientId: string
  ) => {
    return mainApi.fetchJSON({
      path: `/accounting_clients/${clientId}`,
      method: "PUT",
      body: {
        ...clientData,
      },
      schema: z.union([
        z.object({
          status: z.literal(409),
          body: z.object({
            detail: z.union([
              z.literal("client-number-is-taken"),
              z.literal("email-is-taken"),
              z.literal("vat-id-is-taken"),
            ]),
          }),
        }),
        z.object({
          status: z.literal(200),
          body: AccountingClientSchema,
        }),
      ]),
    });
  };

  return {
    createAccountingClient,
    updateAccountingClient,
  };
};
