import { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import {
  getCommunicationErrorMessage,
  getCommunicationErrorTitle,
} from "../../../../../../../communication-errors/communication-error-messages";
import { useMainApi } from "../../../../../../../main-api";
import z from "zod";
import styles from "./send-email-to-accounting-client.module.scss";
import { useCurrentLanguage } from "../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  success: "Erfolg",
  successDetail:
    "Wir haben eine E-Mail an Ihren Mandanten gesendet, um ihn darüber zu informieren, dass er seine Bankkonten mit dieser Plattform verbinden soll.",
  emailInstruction:
    "Senden Sie eine E-Mail an den Mandanten, um ihn durch den Prozess der Verbindung seiner Bankkonten mit unserer Plattform zu führen.",
  sendEmailButton: "E-Mail senden",
};

const ENGLISH_TRANSLATIONS = {
  success: "Success",
  successDetail:
    "We've sent an email to your client notifying them to connect their bank accounts to this platform.",
  emailInstruction:
    "Send an email to the client to guide them through the process of connecting their bank accounts to our platform.",
  sendEmailButton: "Send email",
};

export function SendEmailToAccountingClient({
  accountingClientId,
}: {
  accountingClientId: string;
}) {
  const mainApi = useMainApi();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toastRef = useRef<Toast>(null);
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const sendEmail = async () => {
    setIsSubmitting(true);

    const response = await mainApi.fetchJSON({
      path: `/accounting_clients/${accountingClientId}/bank_accounts/trigger_new_bank_account_with_plaid_link_email`,
      method: "POST",
      schema: z.object({
        status: z.literal(200),
        body: z.object({}),
      }),
    });

    setIsSubmitting(false);

    if (response.error) {
      toastRef.current?.show({
        severity: "error",
        summary: getCommunicationErrorTitle(response.error),
        detail: getCommunicationErrorMessage(response.error),
      });
      return;
    }

    toastRef.current?.show({
      severity: "success",
      summary: translations.success,
      detail: translations.successDetail,
    });
  };

  return (
    <div className={styles.emailFlowContainer}>
      <Toast ref={toastRef} />
      <span>{translations.emailInstruction}</span>
      <div className={styles.buttonWrapper}>
        <Button
          onClick={sendEmail}
          label={translations.sendEmailButton}
          disabled={isSubmitting}
          loading={isSubmitting}
        />
      </div>
    </div>
  );
}
