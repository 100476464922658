import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FileUpload } from "primereact/fileupload";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { useContext, useRef, useState } from "react";
import styles from "./document-upload-dialog.module.scss";
import { useMainApi } from "../../../../../../main-api";
import { z } from "zod";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { InputText } from "primereact/inputtext";
import {
  getCommunicationErrorTitle,
  getCommunicationErrorMessage,
} from "../../../../../../communication-errors/communication-error-messages";
import { CommunicationError } from "../../../../../../communication-errors/communication-errors";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";
import { throwError } from "../../../../../../throw-error";
import {
  DocumentSchema,
  ResultsContext,
} from "../../../results/results-context";

const GERMAN_TRANSLATIONS = {
  uploadDocument: "Dokument hochladen",
  documentName: "Dokumentname:",
  documentNameIsRequired: "Dokumentname ist erforderlich",
  chooseFile: "Wählen",
  uploadFile: "Hochladen",
  clearFile: "Löschen",
  successMessage: "Datei erfolgreich hochgeladen",
  emptyTemplateMessage: "Datei hierher ziehen oder Datei auswählen",
  invalidFileTypeError: "Ungültiger Dateityp",
  invalidFileTypeErrorMessage: "Bitte laden Sie eine PDF-Datei hoch",
};

const ENGLISH_TRANSLATIONS = {
  uploadDocument: "Upload Document",
  documentName: "Document Name:",
  documentNameIsRequired: "Document Name is required",
  chooseFile: "Choose",
  uploadFile: "Upload",
  clearFile: "Clear",
  successMessage: "File Uploaded Successfully",
  emptyTemplateMessage: "Drag and drop file here or Choose File",
  invalidFileTypeError: "Invalid file type",
  invalidFileTypeErrorMessage: "Please upload a PDF file",
};

export function DocumentUploadDialog() {
  const mainApi = useMainApi();
  const toastRef = useRef<Toast | null>(null);
  const { setResults } = useContext(ResultsContext) || throwError();

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [documentState, setDocumentState] = useState<{
    loading?: boolean;
    error?: CommunicationError | "invalid-file-type";
  }>({});

  const [dialogState, setDialogState] = useState<{
    visible: boolean;
  }>({ visible: false });

  const form = useForm({
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, translations.documentNameIsRequired).trim(),
      })
    ),
    defaultValues: {
      name: "",
    },
  });

  return (
    <>
      <Toast ref={toastRef} />

      <Button
        icon="pi pi-cloud-upload"
        onClick={() => setDialogState({ visible: true })}
        label={translations.uploadDocument}
      />
      <Dialog
        header={translations.uploadDocument}
        visible={dialogState.visible}
        draggable={false}
        className={styles.dialog}
        onHide={() => setDialogState({ visible: false })}
      >
        {(() => {
          if (documentState.loading) {
            return (
              <div className={styles.spinner}>
                <ProgressSpinner />
              </div>
            );
          }

          return (
            <form>
              <div className={`${styles.row} ${styles.margin}`}>
                <div className={styles.formField}>
                  <label htmlFor={"name"}>
                    <b>{translations.documentName}</b>
                  </label>
                  <div className={styles.inputGroup}>
                    <Controller
                      name="name"
                      control={form.control}
                      render={({ field, fieldState }) => (
                        <>
                          <InputText
                            id="name"
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            className={`${styles.input} ${fieldState.error ? "p-invalid" : ""}`}
                          />
                          {fieldState.error && (
                            <small className="p-error">
                              {fieldState.error.message}
                            </small>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>

              <FileUpload
                name="file"
                customUpload
                uploadHandler={(e) => {
                  form.handleSubmit(async (data) => {
                    const file = e.files[0];

                    if (!file) {
                      return;
                    }

                    setDocumentState({ loading: true });

                    const formData = new FormData();
                    formData.append("file", file);

                    const urlSearchParams = new URLSearchParams();
                    urlSearchParams.append("name", data.name);

                    const res = await mainApi.fetch(
                      `/documents/accounting-client/create?${urlSearchParams.toString()}`,
                      [200, 400],
                      {
                        method: "POST",
                        body: formData,
                      }
                    );

                    if (res.error) {
                      setDocumentState({ error: res.error });

                      toastRef.current!.show({
                        severity: "error",
                        summary: getCommunicationErrorTitle(res.error),
                        detail: getCommunicationErrorMessage(res.error),
                      });

                      return;
                    }

                    if (!res.response.ok) {
                      const errorResponse = await res.response.json();

                      if (
                        res.response.status === 400 &&
                        errorResponse.detail === "invalid-file-type"
                      ) {
                        setDocumentState({
                          error: "invalid-file-type",
                        });

                        toastRef.current!.show({
                          severity: "error",
                          summary: translations.invalidFileTypeError,
                          detail: translations.invalidFileTypeErrorMessage,
                        });

                        return;
                      }

                      setDocumentState({
                        error: CommunicationError.UnexpectedResponse,
                      });

                      toastRef.current!.show({
                        severity: "error",
                        summary: getCommunicationErrorTitle(
                          CommunicationError.UnexpectedResponse
                        ),
                        detail: getCommunicationErrorMessage(
                          CommunicationError.UnexpectedResponse
                        ),
                      });

                      return;
                    }

                    toastRef.current!.show({
                      severity: "success",
                      summary: "Success",
                      detail: translations.successMessage,
                    });

                    const responseJSON = await res.response.json();
                    const document = DocumentSchema.parse(responseJSON);

                    setResults((results) => {
                      if (!results.data) {
                        return results;
                      }

                      return {
                        data: {
                          rows: [
                            {
                              ...document,
                              properties: {
                                size: `${(file.size / (1024 * 1024)).toFixed(2)} MB`,
                                type: `${file.type}`,
                              },
                            },
                            ...results.data.rows,
                          ],
                        },
                      };
                    });

                    form.reset({ name: "" });
                    setDocumentState({});
                    setDialogState({ visible: false });
                  })();
                }}
                chooseOptions={{
                  icon: "pi pi-fw pi-folder-open",
                  label: translations.chooseFile,
                  className: "custom-choose-btn",
                }}
                uploadOptions={{
                  icon: "pi pi-fw pi-cloud-upload",
                  label: translations.uploadFile,
                  className: "custom-upload-btn p-button-success",
                }}
                cancelOptions={{
                  icon: "pi pi-fw pi-times",
                  label: translations.clearFile,
                  className: "custom-cancel-btn p-button-danger",
                }}
                headerTemplate={(options) => {
                  const {
                    className,
                    chooseButton,
                    uploadButton,
                    cancelButton,
                  } = options;
                  return (
                    <div
                      className={className}
                      style={{
                        backgroundColor: "transparent",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      {chooseButton}
                      {uploadButton}
                      {cancelButton}
                    </div>
                  );
                }}
                itemTemplate={(inFile, props) => {
                  const file = inFile as File;

                  return (
                    <div className={styles.documentGroup}>
                      <div className={styles.documentBar}>
                        <i className={`pi pi-file-pdf ${styles.icon}`} />
                        <span className={styles.documentItem}>
                          {file.name}
                          <small>{new Date().toLocaleDateString()}</small>
                        </span>
                      </div>
                      <Tag
                        value={props.formatSize}
                        severity="warning"
                        className={`px-3 py-2 ${styles.tag}`}
                      />
                      <Button
                        type="button"
                        icon="pi pi-times"
                        className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                        onClick={(e) => {
                          props.onRemove(e);
                        }}
                      />
                    </div>
                  );
                }}
                emptyTemplate={() => {
                  return (
                    <div className={styles.empty}>
                      <i
                        className={`pi pi-cloud-upload ${styles.uploadIcon}`}
                      />
                      <span>{translations.emptyTemplateMessage}</span>
                    </div>
                  );
                }}
              />
            </form>
          );
        })()}
      </Dialog>
    </>
  );
}
