import { z } from "zod";
import validator from "validator";

const IbanSchema = (translations: { ibanValidationMessage: string }) =>
  z.object({
    iban: z.string().refine(
      (value) => {
        return validator.isIBAN(value);
      },
      {
        message: translations.ibanValidationMessage,
      }
    ),
  });

export const VatAmountSchema = (translations: {
  vatRateValidationMessage: string;
  vatAmountValidationMessage: string;
}) =>
  z.object({
    vat_rate: z.number().refine((value) => value > 0, {
      message: translations.vatRateValidationMessage,
    }),
    vat_amount: z.number().refine((value) => value > 0, {
      message: translations.vatAmountValidationMessage,
    }),
  });

export const GeneralInformationSchema = (translations: {
  vatRateValidationMessage: string;
  vatAmountValidationMessage: string;
  ibanValidationMessage: string;
}) =>
  z.object({
    date: z.coerce.date().nullable(),
    due_date: z.coerce.date().nullable(),
    total_amount: z.coerce.number().nullable(),
    subtotal: z.coerce.number().nullable(),
    currency: z.string().nullable(),
    iban_list: z.array(IbanSchema(translations)),
    vat_amounts: z.array(VatAmountSchema(translations)),
    invoice_number: z.string().nullable(),
    client_source_name: z.string().nullable(),
    client_source_address: z.string().nullable(),
    client_destination_name: z.string().nullable(),
    client_destination_address: z.string().nullable(),
    client_source_vat_id: z.string().nullable(),
    client_source_tax_number: z.string().nullable(),
    payment_terms: z.string().nullable(),
  });

export type EditGeneralInformation = z.infer<
  ReturnType<typeof GeneralInformationSchema>
>;

export const ItemDetailSchema = z.object({
  item_description: z.string().nullable(),
  item_quantity: z.coerce.number().nullable(),
  item_date: z.coerce.date().nullable(),
  unit: z.string().nullable(),
  unit_price: z.coerce.number().nullable(),
  currency: z.string().nullable(),
  product_code: z.string().nullable(),
  tax: z.coerce.number().nullable(),
  amount: z.coerce.number().nullable(),
});

export type ItemDetail = z.infer<typeof ItemDetailSchema>;

const ChangedSchema = (translations: {
  ibanValidationMessage: string;
  vatRateValidationMessage: string;
  vatAmountValidationMessage: string;
}) =>
  z.object({
    document_type: z.string().nullable(),
    general_information: GeneralInformationSchema(translations),
    item_details: z.array(ItemDetailSchema),
  });

export const EditDocumentSchema = (translations: {
  documentNameMessage: string;
  ibanValidationMessage: string;
  vatRateValidationMessage: string;
  vatAmountValidationMessage: string;
}) =>
  z.object({
    document_name: z
      .string()
      .min(1, { message: translations.documentNameMessage }),
    changed: ChangedSchema(translations),
  });

export type EditDocument = z.infer<ReturnType<typeof EditDocumentSchema>>;
