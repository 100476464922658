import { useContext, useMemo, useCallback, useRef } from "react";
import { Column } from "react-data-grid";
import { SortColumn as ReactDataGridSortColumn } from "react-data-grid";
import { DEFAULT_SORT_COLUMN } from "./results/sorting/sorting-constants";
import styles from "./nominal-accounts.module.scss";
import { ProgressSpinner } from "primereact/progressspinner";
import { getCommunicationErrorMessage } from "../../../../../../../../communication-errors/communication-error-messages";
import { throwError } from "../../../../../../../../throw-error";
import { NominalAccountsToolbar } from "./toolbar/nominal-accounts-toolbar";
import { NominalAccountsFiltersContextProvider } from "./toolbar/filters-context";
import {
  SortColumn,
  NominalAccountsSortingContext,
  NominalAccountsSortingContextProvider,
} from "./results/sorting/sorting-context";
import {
  NominalAccount,
  NominalAccountsResultsContext,
  NominalAccountsResultsContextProvider,
} from "./results/results-context";
import { AccountingClient } from "../../../accountingClientLoader";
import { Checkbox } from "primereact/checkbox";
import { Menu } from "primereact/menu";
import { ContextMenu } from "./results/context-menu";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useCurrentLanguage } from "../../../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../../../language/supported-languages";
import { ReactDataGrid } from "../../../../../../../../react-data-grid";

const GERMAN_TRANSLATIONS = {
  accountNumber: "Kontonummer",
  accountName: "Kontoname",
  bankAccount: "Bankkonto",
  bookings: "Buchungen",
  loadingMoreRows: "Weitere Zeilen werden geladen...",
};

const ENGLISH_TRANSLATIONS = {
  accountNumber: "Account Number",
  accountName: "Account Name",
  bankAccount: "Bank Account",
  bookings: "Bookings",
  loadingMoreRows: "Loading more rows...",
};

function _NominalAccounts({
  accountingClient,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  const { results, setOffset } =
    useContext(NominalAccountsResultsContext) || throwError();

  const selectedAccount = results.data?.rows.find(
    (account) => account.account_id === selectedAccountId
  );

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const sorting = useContext(NominalAccountsSortingContext) || throwError();

  const menuRef = useRef<Menu>(null);

  const toastRef = useRef<Toast>(null);

  const getRowKey = useCallback(
    (r: Partial<NominalAccount>) => r.id || throwError(),
    []
  );

  const onSortColumnsChange = useCallback(
    (sortColumns: ReactDataGridSortColumn[]) => {
      const sortColumn = sortColumns[0];
      sorting.setSortColumn((sortColumn ?? DEFAULT_SORT_COLUMN) as SortColumn);
    },
    [sorting]
  );

  const sortColumns = useMemo(() => {
    return [sorting.sortColumn];
  }, [sorting]);

  const columns: Column<NominalAccount>[] = useMemo(() => {
    return [
      {
        key: "account_number",
        name: `${translations.accountNumber}`,
        editable: true,
        sortable: true,
      },
      {
        key: "account_name",
        name: `${translations.accountName}`,
        sortable: true,
      },
      {
        key: "is_connected_to_bank_account",
        name: `${translations.bankAccount}`,
        renderCell: (args) => {
          if (!args.row.is_connected_to_bank_account) {
            return <></>;
          }
          return (
            <span>
              {args.row.is_connected_to_bank_account === true ? (
                <div className={styles.rowIconWrapper}>
                  <Checkbox checked></Checkbox>
                </div>
              ) : null}
            </span>
          );
        },
        sortable: true,
      },
      {
        key: "total_transactions",
        name: `${translations.bookings}`,
        sortable: true,
      },
    ];
  }, [translations]);

  return (
    <div className={styles.page}>
      <Toast ref={toastRef} />
      <ConfirmDialog />
      <NominalAccountsToolbar />
      {(() => {
        const data = results.data;

        if (results.loading && !results.data) {
          return (
            <div className={styles.loadingContainer}>
              <ProgressSpinner />
            </div>
          );
        }

        if (results.error) {
          return (
            <div className={styles.errorContainer}>
              <p className={styles.errorMessage}>
                {getCommunicationErrorMessage(results.error)}
              </p>
            </div>
          );
        }

        if (data && data.rows.length > 0) {
          return (
            <>
              <ReactDataGrid
                columns={columns}
                rows={results.data.rows}
                sortColumns={sortColumns}
                rowKeyGetter={getRowKey}
                onSortColumnsChange={onSortColumnsChange}
                onWheelAndBottom={() => {
                  setOffset(data.rows.length);
                }}
                className={`${styles.table}`}
                onCellClick={(args, event) => {
                  setSelectedAccountId(args.row.account_id);
                  menuRef.current?.toggle(event);
                }}
              />
              <ContextMenu
                selectedAccount={selectedAccount}
                accountingClientId={accountingClient.id}
                menuRef={menuRef}
                toastRef={toastRef}
              />
              {results.loading && (
                <div className={styles.loadingNextPageContainer}>
                  {translations.loadingMoreRows}
                </div>
              )}
            </>
          );
        }
      })()}
    </div>
  );
}

export default function NominalAccounts({
  accountingClient,
  selectedAccountId,
  setSelectedAccountId,
}: {
  accountingClient: AccountingClient;
  selectedAccountId: string | undefined;
  setSelectedAccountId: (id: string | undefined) => void;
}) {
  return (
    <NominalAccountsFiltersContextProvider accountingClient={accountingClient}>
      <NominalAccountsSortingContextProvider>
        <NominalAccountsResultsContextProvider
          accountingClient={accountingClient}
        >
          <_NominalAccounts
            accountingClient={accountingClient}
            selectedAccountId={selectedAccountId}
            setSelectedAccountId={setSelectedAccountId}
          />
        </NominalAccountsResultsContextProvider>
      </NominalAccountsSortingContextProvider>
    </NominalAccountsFiltersContextProvider>
  );
}
