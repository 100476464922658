import { Document } from "../../results/results-context";
import { MAIN_API_URL } from "../../../../../main-api";
import styles from "./pdf-file-preview.module.scss";
import { Button } from "primereact/button";
import { useCurrentLanguage } from "../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  download: "Herunterladen",
};

const ENGLISH_TRANSLATIONS = {
  download: "Download",
};

export function PdfFilePreview(props: { document: Document }) {
  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  return (
    <div className={styles.previewContainer}>
      <>
        <iframe
          src={`${MAIN_API_URL}/documents/accounting-client/${props.document.id}/file#toolbar=0`}
          width="100%"
        />
        <div className={styles.overlay}>
          <Button
            label={translations.download}
            icon="pi pi-download"
            onClick={() => {
              window.open(
                `${MAIN_API_URL}/documents/accounting-client/${props.document.id}/file`
              );
            }}
          />
        </div>
      </>
    </div>
  );
}
