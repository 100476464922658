import { DocumentDataScreen } from "./document-data-screen/document-data-screen";
import { Results } from "./results/results";
import { ResultsContextProvider } from "./results/results-context";
import { QuickFiltersContextProvider } from "./top-section/navbar/quick-filters/quick-filters-context";
import { TopSection } from "./top-section/top-section";
import styles from "./page.module.scss";
import { ReactNode } from "react";
import { SortingContextProvider } from "./sorting/sorting-context";
import { FiltersContextProvider } from "./top-section/navbar/filters/filters-context";

function Page(props: { children: ReactNode }) {
  return <div className={styles.page}>{props.children}</div>;
}

export function DocumentsPage() {
  return (
    <>
      <QuickFiltersContextProvider>
        <FiltersContextProvider>
          <SortingContextProvider>
            <ResultsContextProvider>
              <Page>
                <TopSection />
                <Results />
                <DocumentDataScreen />
              </Page>
            </ResultsContextProvider>
          </SortingContextProvider>
        </FiltersContextProvider>
      </QuickFiltersContextProvider>
    </>
  );
}
