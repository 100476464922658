import z from "zod";
import { useState, useRef, useEffect } from "react";
import { AccountingClient } from "../accountingClientLoader";
import { CommunicationError } from "../../../../../../communication-errors/communication-errors";
import { useMainApi } from "../../../../../../main-api";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { Message } from "primereact/message";
import { ConnectedBankAccountCards } from "./connected-bank-account-cards/connected-bank-account-cards";
import { AddBankAccountWithIBAN } from "./add-new-bank-account-with-iban/add-new-bank-account-with-iban";
import { SendEmailToAccountingClient } from "./send-email-to-accounting-client/send-email-to-accounting-client";
import { ConnectBankAccountsToNominalAccounts } from "./connect-bank-accounts-to-nominal-accounts/connect-bank-accounts-to-nominal-accounts";
import styles from "./bank-information.module.scss";
import { useCurrentLanguage } from "../../../../../../language/current-language";
import { SupportedLanguage } from "../../../../../../language/supported-languages";

const GERMAN_TRANSLATIONS = {
  error: "Fehler",
  failedToFetchBankInformation: "Fehler beim Laden der Bankinformationen",
  loadingError: "Beim Laden der Bankinformationen ist ein Fehler aufgetreten.",
  connectedBankAccounts: "Verbundene Bankkonten",
  newBankAccountWithPlaid: "Neues Bankkonto mit Plaid",
  newBankAccountWithIban: "Neues Bankkonto mit IBAN",
  assignNominalAccounts: "Sachkonten den Bankkonten zuweisen",
  limitedNominalAccountsMessage:
    "Begrenzte Sachkonten zur Auswahl. Bitte sehen Sie sich weitere Konten an",
  uploadGDPDU: "Laden Sie die GDPDU-Datei hoch.",
};

const ENGLISH_TRANSLATIONS = {
  error: "Error",
  failedToFetchBankInformation: "Failed to fetch bank information",
  loadingError: "There was an error loading the bank information.",
  connectedBankAccounts: "Connected bank accounts",
  newBankAccountWithPlaid: "New bank account with Plaid",
  newBankAccountWithIban: "New bank account with IBAN",
  assignNominalAccounts: "Assign nominal accounts to bank accounts",
  limitedNominalAccountsMessage:
    "Limited nominal accounts to choose from. To see more accounts, please",
  uploadGDPDU: "upload GDPDU File.",
};

export const NominalAccountSchema = z.object({
  id: z.string().uuid(),
  account_number: z.number(),
  account_name: z.string(),
});

export type NominalAccountToConnect = z.infer<typeof NominalAccountSchema>;

const BankAccountWithNominalAccountSchema = z.object({
  id: z.string().uuid(),
  iban: z.string(),
  nominal_account: NominalAccountSchema,
});

export type BankAccountWithNominalAccount = z.infer<
  typeof BankAccountWithNominalAccountSchema
>;

const BankAccountWithoutNominalSchema = z.object({
  id: z.string().uuid(),
  iban: z.string(),
});

export type BankAccountWithoutNominalAccount = z.infer<
  typeof BankAccountWithoutNominalSchema
>;

export const AccountingClientBankAccountsSchema = z.object({
  bank_accounts_with_nominal_accounts: z.array(
    BankAccountWithNominalAccountSchema
  ),
  bank_accounts_without_nominal_accounts: z.array(
    BankAccountWithoutNominalSchema
  ),
  nominal_accounts_to_connect: z.array(NominalAccountSchema),
});

export type AccountingClientBankAccounts = z.infer<
  typeof AccountingClientBankAccountsSchema
>;

export default function BankInformation(props: {
  accountingClient: AccountingClient;
  switchToDocumentsTab: () => void;
}) {
  const mainApi = useMainApi();
  const toastRef = useRef<Toast>(null);

  const currentLanguage = useCurrentLanguage();
  const translations =
    currentLanguage === SupportedLanguage.German
      ? GERMAN_TRANSLATIONS
      : ENGLISH_TRANSLATIONS;

  const [
    accountingClientBankInformationState,
    setAccountingClientBankInformationState,
  ] = useState<{
    loading?: boolean;
    data?: AccountingClientBankAccounts | null;
    error?: CommunicationError | undefined;
  }>({});

  useEffect(() => {
    const fetchAccountingClientBankAccounts = async () => {
      setAccountingClientBankInformationState({ loading: true });
      const res = await mainApi.fetchJSON({
        method: "GET",
        path: `/accounting_clients/${props.accountingClient.id}/bank_accounts`,
        schema: z.object({
          status: z.literal(200),
          body: AccountingClientBankAccountsSchema,
        }),
      });

      if (res.error) {
        setAccountingClientBankInformationState({ error: res.error });
        toastRef.current?.show({
          severity: "error",
          summary: translations.error,
          detail: translations.failedToFetchBankInformation,
        });
        return;
      }

      setAccountingClientBankInformationState({ data: res.response.body });
    };

    fetchAccountingClientBankAccounts();
  }, [
    mainApi,
    props.accountingClient.id,
    translations.error,
    translations.failedToFetchBankInformation,
  ]);

  return (
    <div className={styles.page}>
      <Toast ref={toastRef} />
      {(() => {
        if (accountingClientBankInformationState.loading) {
          return (
            <div className={styles.loadingContainer}>
              <ProgressSpinner />
            </div>
          );
        }

        if (accountingClientBankInformationState.error) {
          return (
            <div className={styles.errorContainer}>
              <Message severity="error" text={translations.loadingError} />
            </div>
          );
        }

        if (accountingClientBankInformationState.data) {
          return (
            <>
              <div className={`${styles.marginBottom3}`}>
                <h2 className={`${styles.marginBottom2}`}>
                  {translations.connectedBankAccounts}
                </h2>
                <div className={styles.cardsContainer}>
                  <ConnectedBankAccountCards
                    bankAccountsWithNominalAccounts={
                      accountingClientBankInformationState.data
                        .bank_accounts_with_nominal_accounts
                    }
                  />
                </div>
              </div>
              <div className={`${styles.marginBottom3}`}>
                <h2 className={`${styles.marginBottom2}`}>
                  {translations.newBankAccountWithPlaid}
                </h2>
                <SendEmailToAccountingClient
                  accountingClientId={props.accountingClient.id}
                />
              </div>
              <div className={`${styles.marginBottom3}`}>
                <h2 className={`${styles.marginBottom2}`}>
                  {translations.newBankAccountWithIban}
                </h2>
                <AddBankAccountWithIBAN
                  accountingClientId={props.accountingClient.id}
                  setResults={(newResults) =>
                    setAccountingClientBankInformationState((prevState) => ({
                      ...prevState,
                      data: newResults,
                    }))
                  }
                />
              </div>
              <div className={`${styles.marginBottom3}`}>
                <h2 className={`${styles.marginBottom2}`}>
                  {translations.assignNominalAccounts}
                </h2>
                <div className={`${styles["display-flex"]}`}>
                  {!props.accountingClient.has_gdpdu_upload && (
                    <Message
                      className={`${styles.marginBottom2} ${styles.width100}`}
                      severity="info"
                      text={
                        <>
                          {translations.limitedNominalAccountsMessage}{" "}
                          <span
                            className={"link-inherit"}
                            onClick={props.switchToDocumentsTab}
                          >
                            {translations.uploadGDPDU}
                          </span>
                        </>
                      }
                    />
                  )}
                </div>
                <ConnectBankAccountsToNominalAccounts
                  accountingClientId={props.accountingClient.id}
                  results={
                    accountingClientBankInformationState.data || {
                      bank_accounts_with_nominal_accounts: [],
                      bank_accounts_without_nominal_accounts: [],
                      nominal_accounts_to_connect: [],
                    }
                  }
                  setResults={(newResults) =>
                    setAccountingClientBankInformationState((prevState) => ({
                      ...prevState,
                      data: newResults,
                    }))
                  }
                />
              </div>
            </>
          );
        }

        return null;
      })()}
    </div>
  );
}
